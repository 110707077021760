import * as React from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField, Typography
} from '@mui/material';
import { getIcon } from './CustomIcon';
import { ChangeEvent, useContext } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { crudStatus } from '../../../../core/constants/common';
import AddIcon from '@mui/icons-material/Add';
import { Social } from './Social';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';

interface SocialsProps {
  data: any[];
  handleAddSocial?: any;
  handleSocialChange: Function;
  handleSocialTitleChange?: Function;
  handleDeleteSocial?: Function;
  checkIsMandatory?: Function;
  addButtonLabel?: string;
  isAddable?: boolean;
  isDeletable?: boolean;
  isSocialSelectEditable?: boolean;
  errors?: any;
}

const socialList = [
  {
    code: 'twitter',
    title: 'Twitter'
  },
  {
    code: 'discord',
    title: 'Discord'
  },
  {
    code: 'instagram',
    title: 'Instagram'
  },
  {
    code: 'youtube',
    title: 'Youtube'
  },
  {
    code: 'github',
    title: 'Github'
  },
  {
    code: 'telegram',
    title: 'Telegram'
  },
  {
    code: 'linkedin',
    title: 'Linkedin'
  },
  {
    code: 'link',
    title: 'Link'
  }
];

export default function SocialList(props: SocialsProps) {
  const {
    data,
    errors = {},
    handleAddSocial,
    addButtonLabel = 'Add social media',
    handleSocialChange,
    handleSocialTitleChange,
    handleDeleteSocial,
    isAddable = true,
    isDeletable = true,
    isSocialSelectEditable = true,
    checkIsMandatory
  } = props;

  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  return (
    <>
      {
        data.map((item, index) => {
            const isMandatory = typeof checkIsMandatory === 'function' ? checkIsMandatory(item) : false;
            return (
              item.status !== crudStatus.DELETED &&
              <Grid container key={index} spacing={2} sx={{ marginBottom: '24px' }}>
                <Grid item xs={6} md={4}>
                  <FormControl fullWidth disabled={!isSocialSelectEditable}
                               error={errors[index] && errors[index].socialCode && errors[index].socialCode.length > 0}
                  >
                    <InputLabel id={`social-name-${index}`}>Name</InputLabel>
                    <Select fullWidth className="icon-select"
                            label="Name"
                            labelId={`social-name-${index}`}
                            value={item.social.socialCode}
                            onChange={(event: SelectChangeEvent) => {
                              const socialCode = event.target.value;
                              handleSocialTitleChange(socialCode, index);
                            }}
                    >
                      {
                        socialList.map((social, index) => <MenuItem key={index} value={social.code}>
                          <div key={index} style={{ display: 'flex' }}>
                            <Social socialCode={social.code}/>
                            <span>{social.title}</span>
                          </div>
                        </MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={isDeletable ? 7 : 8}>
                  <TextField label="Link" variant="outlined" fullWidth
                             onChange={(event: ChangeEvent<HTMLInputElement>) => {
                               handleSocialChange(event.target.value, index);
                             }}
                             error={errors[index] && errors[index].profileUrl && errors[index].profileUrl.length > 0}
                             value={item.profileUrl}/>
                </Grid>
                {
                  isDeletable && !isMandatory && <Grid item xs={1} sx={{ display: 'flex' }}>
                    <div className="icon-button-wrapper">
                      <IconButton onClick={() => {
                        handleDeleteSocial(index);
                      }}>
                        <ClearIcon/>
                        {isPhone && <Typography style={{ marginLeft: '16px' }}>Delete</Typography>}
                      </IconButton>
                    </div>
                  </Grid>
                }
              </Grid>
            );
          }
        )
      }
      {
        isAddable && <Grid item xs={12}>
          <Button startIcon={<AddIcon/>}
                  onClick={handleAddSocial}
                  sx={{
                    textTransform: 'none',
                    fontSize: '13px'
                  }}
          >{addButtonLabel}</Button>
        </Grid>
      }
    </>
  );
}


