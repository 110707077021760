import React, { FC, useCallback, useContext } from 'react';
import {
  Box,
  Button, FormControl,
  Grid, InputLabel, MenuItem, Select,
  TextField,
  Typography
} from '@mui/material';
import { Panel } from '../../components/common';
import { ReactComponent as RockerSvg } from '../../../../assets/rocket.svg';
import { useForm } from '../../hooks/useForm';
import { useNavigate } from 'react-router-dom';
import { CREATE_SUPPORT_TICKET } from '../../../../store/mutations/support';
import { useMutation } from '@apollo/client';
import { SupportTicketCategoryEnum, SupportTicketInput } from '../../../../store/generated-models';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';

type SupportForm = {
  email: string;
  topic: string;
  description: string;
}

type Topic = {
  code: SupportTicketCategoryEnum,
  title: string
}

export const ContactSupport: FC = () => {
  const navigate = useNavigate();
  const [createSupportTicket] = useMutation(CREATE_SUPPORT_TICKET, {fetchPolicy: 'no-cache'});
  const {enqueueSnackbar} = useSnackbar();
  const screenContext = useContext(ScreenContext);
  const {isPhone} = screenContext;

  const supportTopics: Topic[] = [
    {code: SupportTicketCategoryEnum.Saft, title: 'Contract contract'},
    {code: SupportTicketCategoryEnum.ParticipationRequest, title: 'Participation request'},
    {code: SupportTicketCategoryEnum.Other, title: 'Other'}
  ];

  const checkEmptyValue = (value: string) => {
    return value.length > 0;
  };
  const validationConfig = {
    email: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The email cannot be empty'
      }
    },
    topic: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The title cannot be empty'
      }
    },
    description: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The description cannot be empty'
      }
    }
  };
  const initialValues: SupportForm = {
    email: '',
    topic: '',
    description: ''
  };

  const onSubmit = (data: any) => {
    const ticket: SupportTicketInput = {
      topic: data.topic,
      email: data.email,
      description: data.description
    };

    createSupportTicket({variables: {ticket}})
      .then(() => {
        enqueueSnackbar('Question has been sent', {variant: 'success'});
      }).catch((error: any) => {
      enqueueSnackbar(error.message, {...{variant: 'error'}});
    });
  };

  const {
    handleTextChange, handleSelectChange, handleSubmit, errors, data
  } = useForm<SupportForm>({validationConfig, initialValues, onSubmit: () => onSubmit(data)});

  const handleCancel = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <Box sx={{maxWidth: '1480px'}}>
      <Panel>
        <Grid container spacing={3}>
          <Grid item xs={8} sm={6}>
            <Typography sx={{fontSize: '16px', color: '#7A858F'}}>
              Enter a brief description of your question to help prepare our specialists for your support decision
            </Typography>
          </Grid>
          {isPhone ? <Grid item xs={4}>
              <RockerSvg width={'100%'} height=""/>
            </Grid>
            : <Grid item xs={6}></Grid>
          }
          <Grid item sm={6} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="span" sx={{fontWeight: 600, fontSize: '16px'}}>
                  Personal Information
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email" name="email" variant="outlined"
                           fullWidth onChange={handleTextChange} value={data.email}
                           error={errors.email && errors.email.length > 0}
                           sx={{marginBottom: '40px'}}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="span" sx={{fontWeight: 600, fontSize: '16px'}}>
                  Question
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id={'topic'}>Topic</InputLabel>
                  <Select fullWidth
                          label="Topic"
                          labelId="topic"
                          name="topic"
                          value={data.topic || 'other'}
                          onChange={handleSelectChange}
                  >
                    {
                      supportTopics.length > 0 && supportTopics.map((topic: any, index: number) => (
                        <MenuItem key={index} value={topic.code}>
                          <Typography>{topic.title}</Typography>
                        </MenuItem>)
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField label="Question description" name="description" variant="outlined" multiline rows={5}
                           fullWidth onChange={handleTextChange} value={data.description}
                           error={errors.description && errors.description.length > 0}
                           sx={{marginBottom: '40px'}}
                />
              </Grid>

              <Grid item xs={6}>
                <Button variant="outlined" sx={{width: {xs: '146px', md: 'auto'}}} onClick={handleCancel}>Cancel</Button>
              </Grid>
              <Grid item xs={6}>
                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Button variant="contained" sx={{width: {xs: '146px', md: 'auto'}}} onClick={handleSubmit}>Send question</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {!isPhone && <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}}>
            <RockerSvg width={'60%'} height=""/>
          </Grid>
          }
        </Grid>
      </Panel>
    </Box>
  );
};
