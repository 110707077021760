import React, {forwardRef, useEffect, useState} from 'react';
import {
  Box,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
  LinkProps
} from '@mui/material';
import {StepProps} from '../ParticipationRequest';
import {useQuery} from '@apollo/client';
import {GET_PROJECT_BY_ID} from '../../../../../store/queries/projects';
import {useParams} from 'react-router-dom';
import {Project} from '../../../../../store/generated-models';
import {styled} from '@mui/material/styles';

const StyledLink = styled(Link)<LinkProps>(({theme}) => ({
  fontSize: '16px',
  fontWeight: 600,
  textDecoration: 'none',
  color: theme.palette.primary.main
}));

export const AgreeTerms: StepProps = forwardRef((props: any, ref) => {
  let {id} = useParams();

  const {data, loading} = useQuery(GET_PROJECT_BY_ID, {
    variables: {
      projectId: id
    },
    fetchPolicy: 'network-only'
  });
  const [projectData, setProjectData] = useState<Project>();

  useEffect(() => {
    if (!loading) {
      setProjectData(data.getProjectById);
    }
  }, [data]);

  const handleChangeAgree = (event: any) => {
    props.setIsStepValid(event.target.checked);
  };

  const projectLink = (
    <StyledLink href={'/private/projects'}>
      {projectData && projectData.title}
    </StyledLink>
  );
  const termsAndConditionsLink = (
    <StyledLink href="https://defiedtrust.com/terms" target='_blank'>
      Terms and conditions
    </StyledLink>
  );

  return (
    <Grid container spacing={7}>
      <Grid item sm={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Typography variant="body1"
                      sx={{
                        fontSize: '16px',
                        color: '#000000',
                        marginBottom: '12px'
                      }}
          >
            Thank you for your interest in {projectLink}.
          </Typography>
          <Typography sx={{color: '#7A858F', whiteSpace: 'pre-line'}}>
            {projectData && projectData.description}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={6}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
          }}
        >
          <Typography variant="body1"
                      sx={{
                        fontSize: '16px',
                        color: '#000000',
                        marginBottom: '12px'
                      }}
          >
            By registering:
          </Typography>
          <Box sx={{marginLeft: '20px', marginBottom: '16px'}}>
            <ul>
              <li>
                <Typography sx={{marginBottom: '16px'}}>
                  I agree that I don’t reside in any of the following countries:
                </Typography>
                <Typography sx={{marginBottom: '16px'}}>
                  Canada, Costa Rica, United States, North Korea, Iran, Iraq, Lebanon, Libya, Somalia, South Sudan, Sudan, Syria, Venezuela, Yemen, and residents in Russia.
                </Typography>
              </li>
              <li>
                <Typography>
                  I acknowledge that I have read and agreed to the {termsAndConditionsLink} and Privacy policy
                </Typography>
              </li>
            </ul>
          </Box>
          <FormControlLabel onChange={handleChangeAgree} control={<Checkbox/>} label="Agree"/>
        </Box>
      </Grid>
    </Grid>
  );
});
