import React, {useContext, useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import {AuthContext} from '../../../core/providers/AuthProvider';

// import {UserRole} from "../../../store/generated-models";

// interface ProtectedContentProps extends RouteComponentProps {
//   pageComponent: any;
//   requireRole?: string;
// }

// export const ProtectedPage: React.FC<ProtectedContentProps> = ({pageComponent, requireRole}) => {
export const ProtectedPage: React.FC<any> = ({pageComponent}) => {
  const authContext = useContext(AuthContext);

  const [authState, setAuthState] = useState<'loading' | 'allowed' | 'rejected'>('loading');

  useEffect(() => {
    if (authContext.isInitialized) {
      if (
        authContext.user && authContext.user.userId
        // Todo: Left from another team, check:
        //  && (!requireRole || (authContext.user.roles && authContext.user.roles.includes(requireRole)))
      ) {
        setAuthState('allowed');
      } else {
        setAuthState('rejected');
      }
    } else {
      setAuthState('loading');
    }
    // }, [authContext.user, requireRole, authContext.isInitialized]);
  }, [authContext.user, authContext.isInitialized]);

  if (authState === 'loading') {
    return null;
  }

  if (authState === 'allowed') {
    return <>{pageComponent}</>;
  }

  return <Navigate to="/login"/>;

};
