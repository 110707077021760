import {Grid} from '@mui/material';
import {NumberInput} from '../../NumberInput';
import React, {useContext} from 'react';
import {ScreenContext} from "../../../../../../core/providers/ScreenProvider";

interface AllocationProps {
  allocation: any;
  handleChange: Function;
  index: number;
}

export const Allocation = (props: AllocationProps) => {
  const {allocation, handleChange, index} = props;
  const {isPhone, isTablet, isMobile} = useContext(ScreenContext);

  return (
    <>
      <Grid item xs={12} sm={4}>
        { isPhone ?
          <div>
            <div style={{fontWeight: 600}}>{allocation.title} (${allocation.price})</div>
          </div>
          :
          <div>
            <div style={{fontWeight: 600}}>{allocation.title}</div>
            <div>${allocation.price}</div>
          </div>
        }
      </Grid>
      <Grid item xs={6} sm={4}>
        <NumberInput name="tokenCount"
                     label="Tokens Quantity"
                     onChange={(event: any, value: number) => handleChange(event, index, value)}
                     value={allocation.tokenCount}
                     precision={4}
                     fullWidth
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <NumberInput name="investment"
                     label="Investment"
                     onChange={(event: any, value: number) => handleChange(event, index, value)}
                     value={allocation.investment}
                     precision={4}
                     fullWidth
        />
      </Grid>
    </>
  );
};
