import React, { MouseEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { numberFormat } from '../../../../../../core/helpers/utils';
import { AuthContext } from '../../../../../../core/providers/AuthProvider';
import { HsTemplateType, ParticipationRequestStatusEnum } from '../../../../../../store/generated-models';
import { Modal } from '../../Modal/Modal';
import { useMutation, useQuery } from '@apollo/client';
import {
  APPROVE_PARTICIPATION_REQUEST,
  CANCEL_NOVATION,
  DECLINE_PARTICIPATION_REQUEST,
  REQUEST_NOVATION,
  WITHDRAW_APPROVAL_PARTICIPATION_REQUEST
} from '../../../../../../store/mutations/participationRequest';
import { useNavigate } from 'react-router-dom';
import { Panel } from '../../Panel';
import { Allocation } from './Allocation';
import { useDropzone } from 'react-dropzone';
import { getIcon } from '../../CustomIcon';
import { GET_HS_TEMPLATES } from '../../../../../../store/queries/helloSign';
import { ScreenContext } from '../../../../../../core/providers/ScreenProvider';
import { useSnackbar } from 'notistack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface AllocationInterface {
  projectRoundId: string,
  title: string,
  price: number,
  tokenCount?: any,
  investment?: any
}

export const TokensAllocation = (props: any) => {
  const { request, refetch } = props;
  const status = request?.status;
  const { contract, novations } = request;
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const [isOpenWithdraw, setIsOpenWithdraw] = useState(false);
  const [isOpenDecline, setIsOpenDecline] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isOpenNovation, setIsOpenNovation] = useState(false);

  const [withdrawComment, setWithdrawComment] = useState('');
  const [declineComment, setDeclineComment] = useState('');
  // const [approveComment, setApproveComment] = useState('');

  const roundsToAllocations = (allocations: AllocationInterface[]) => {
    return allocations.map(allocation => {
      // investment: allocation.price * parseFloat(allocation.tokenCount)
      return { ...allocation, tokenCount: 0.0, investment: 0.0 };
    });
  };

  const normalizeAllocations = (allocations: any[]) => {
    return allocations ? allocations.map(allocation => ({
      projectRoundId: allocation.projectRound.projectRoundId,
      title: allocation.projectRound.title,
      price: allocation.projectRound.price,
      tokenCount: allocation.tokenCount,
      investment: allocation.totalPrice
    })) : [];
  };

  const latestAllocations = normalizeAllocations(request?.tokenAllocation);
  const defaultAllocations = request.status === ParticipationRequestStatusEnum.Approved || authContext.isUser ?
    normalizeAllocations(request?.tokenAllocation) :
    roundsToAllocations(request?.project?.rounds || []);

  const [allocations, setAllocations] = useState<AllocationInterface[]>(defaultAllocations);

  useEffect(() => {
    setAllocations(defaultAllocations);
  }, [request]);

  const handleChange = (event: any, index: number, value: number) => {
    const newAllocations = [...allocations];
    // @ts-ignore
    newAllocations[index][event.target.name] = value;

    if (event.target.name === 'tokenCount') {
      newAllocations[index].investment = newAllocations[index].price * value;
    } else if (event.target.name === 'investment') {
      newAllocations[index].tokenCount = value / newAllocations[index].price;
    }

    setAllocations(newAllocations);
  };

  const handleWithdraw = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenWithdraw(true);
  }, []);
  const handleDecline = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenDecline(true);
  }, []);
  const handleApprove = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenApprove(true);
  }, []);
  // Todo: Open modal window with Schedule 5 dropzone.
  // const handleNovation = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
  //   setIsOpenNovation(true);
  // }
  const [requestNovation, {
    loading: loadingNovation
  }] = useMutation(REQUEST_NOVATION);
  const handleNovation = async () => {
    await requestNovation({
      variables: {
        participationRequestId: request.projectPRequestId
      }
    }).then(() => {
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const [cancelNovation, {
    loading: loadingCancelNovation
  }] = useMutation(CANCEL_NOVATION);
  const handleCancelNovation = async () => {
    await cancelNovation({
      variables: {
        participationRequestId: request.projectPRequestId
      }
    }).then(() => {
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const { data: helloSignData, loading: helloSignDataLoading } = useQuery(GET_HS_TEMPLATES, {
    variables: {
      templateType: status === ParticipationRequestStatusEnum.Novation
        ? HsTemplateType.ProjectPRequestNovation
        : HsTemplateType.ProjectPRequestContract
    },
    fetchPolicy: 'network-only'
  });

  const defaultTemplate = status !== ParticipationRequestStatusEnum.Novation ?
    request.project.externalTemplateId || '' :
    '';
  const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate);
  const [helloSignTemplates, setHelloSignTemplates] = useState([]);
  useEffect(() => {
    if (helloSignData) {
      setHelloSignTemplates(helloSignData.getHsTemplates);
    }
  }, [helloSignData]);
  useEffect(() => {
    if (!selectedTemplate && helloSignTemplates.length > 0) {
      setSelectedTemplate(helloSignTemplates[0].templateId);
    }
  }, [helloSignTemplates]);

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px',
    borderWidth: 2,
    borderColor: '#BBBDC0',
    borderStyle: 'dashed',
    backgroundColor: '#F5F5F5',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  const focusedStyle = {
    borderColor: '#4A4DF0'
  };
  const loadedStyle = {
    borderColor: '#2BB596',
    borderStyle: 'solid'
  };
  const acceptStyle = {
    borderColor: '#2BB596'
  };
  const rejectStyle = {
    borderColor: '#E2507A'
  };
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    maxFiles: 1,
    accept: 'application/pdf',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles);
    }
  });
  const dropZoneStyle = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
    ...(files.length > 0 ? loadedStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
    files
  ]) as any;

  const [withdrawApprovalParticipationRequest, {
    loading: loadingWithdraw
  }] = useMutation(WITHDRAW_APPROVAL_PARTICIPATION_REQUEST);
  const handleAcceptWithdraw = useCallback(async () => {
    await withdrawApprovalParticipationRequest({
      variables: {
        participationRequestId: request.projectPRequestId,
        comment: withdrawComment
      }
    }).then(() => {
      setIsOpenWithdraw(false);
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  }, [withdrawComment]);

  const [declineParticipationRequest, {
    loading: loadingDecline
  }] = useMutation(DECLINE_PARTICIPATION_REQUEST);
  const handleAcceptDecline = useCallback(async () => {
    await declineParticipationRequest({
      variables: {
        participationRequestId: request.projectPRequestId,
        comment: declineComment
      }
    }).then(() => {
      navigate('/private/requests');
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  }, [declineComment]);

  const [approveParticipationRequest, {
    loading: loadingApprove
  }] = useMutation(APPROVE_PARTICIPATION_REQUEST);
  const handleAcceptApprove = async () => {
    await approveParticipationRequest({
      variables: {
        participationRequestId: request.projectPRequestId,
        externalTemplateId: selectedTemplate,
        tokenAllocation: allocations.map(allocation => {
          const { projectRoundId, tokenCount, investment: totalPrice } = allocation;

          return {
            projectRoundId,
            tokenCount: parseFloat(tokenCount),
            totalPrice: parseFloat(totalPrice)
          };
        }),
        document: files[0]
      }
    }).then(() => {
      setIsOpenApprove(false);
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  const handleDeclineComment = (event: any) => {
    setDeclineComment(event.target.value);
  };
  const handleWithdrawComment = (event: any) => {
    setWithdrawComment(event.target.value);
  };

  const emptyAllocation = authContext.isAdmin ? <>
      <Grid item xs={12}>
        <Typography variant="viewModeValue" sx={{ marginBottom: '16px' }}>
          Please add rounds with prices to the project to proceed with allocation.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Link underline="none"
              href={`/private/project-detailed/${request.project.projectId}`}
              sx={{ fontSize: '14px', fontWeight: 500, display: 'flex', alignItems: 'center', whiteSpace: 'pre' }}
              onClick={(event) => {
                event.preventDefault();
                navigate(`/private/project-detailed/${request.project.projectId}`);
              }}
        >
          Open project <ArrowForwardIcon sx={{ fontSize: '16px' }}/>
        </Link>
      </Grid>
    </> :
    '';

  const viewMode = <Grid container spacing={2}>
    {
      allocations.length > 0 ? allocations.map((allocation: any, index: number) => allocation.tokenCount > 0 &&
        <React.Fragment key={index}>
          <Grid item xs={12} sx={{ marginBottom: index < allocations.length - 1 ? '12px' : '0' }}>
            <Grid container>
              <Grid item xs={12}>
                <div>
                  <div style={{ fontWeight: 600 }}>
                    {`${allocation.title} ${allocation.price ? ' ($' + allocation.price + ')' : ''}`}
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="viewModeLabel" color="text.secondary" component="span">
                  Investment
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="viewModeValue">
                  {numberFormat(allocation.investment, { precision: 4 })} {request.investmentCurrency}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="viewModeLabel" color="text.secondary" component="span">
                  Token Quantity
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="viewModeValue">
                  {numberFormat(allocation.tokenCount, { precision: 4 })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>) : emptyAllocation
    }
  </Grid>;

  const editMode = <Grid container spacing={2}>
    {
      allocations.length > 0 ? allocations.map((allocation: any, index: number) => <React.Fragment key={index}>
          <Allocation allocation={allocation} handleChange={handleChange} index={index}/>
        </React.Fragment>
      ) : emptyAllocation
    }
  </Grid>;

  const templateSelectLabel = status === ParticipationRequestStatusEnum.Novation ? 'Novation Template' : 'Contract Template';

  return <Box sx={{ width: '100%' }}>
    <Modal
      open={isOpenWithdraw}
      title="Withdraw Approval"
      renderActions={() => <>
        <Button onClick={() => setIsOpenWithdraw(false)} disabled={loadingWithdraw}>No</Button>
        <Button onClick={handleAcceptWithdraw} disabled={loadingWithdraw}>Yes</Button>
      </>}
    >
      <>
        <Typography sx={{ marginBottom: '16px' }}>
          Please leave a comment on why you withdraw the approval
        </Typography>
        <TextField label="Comment" name="withdrawComment" variant="outlined" multiline rows={5}
                   fullWidth
                   onChange={handleWithdrawComment} value={withdrawComment}/>
      </>
    </Modal>

    <Modal
      open={isOpenDecline}
      title="Decline Request"
      renderActions={() => <>
        <Button onClick={() => setIsOpenDecline(false)} disabled={loadingDecline}>No</Button>
        <Button onClick={handleAcceptDecline} disabled={loadingDecline}>Yes</Button>
      </>}
    >
      <>
        <Typography sx={{ marginBottom: '16px' }}>
          Please leave a comment on why you declined the application
        </Typography>
        <TextField label="Comment" name="declineComment" variant="outlined" multiline rows={5}
                   fullWidth
                   onChange={handleDeclineComment} value={declineComment}/>
      </>
    </Modal>

    <Modal
      open={isOpenApprove}
      isLoading={helloSignDataLoading}
      title="Approve Request"
      renderActions={() => <>
        <Button disabled={loadingApprove}
                onClick={() => {
                  setFiles([]);
                  setIsOpenApprove(false);
                }}
        >
          Cancel
        </Button>
        <Button onClick={handleAcceptApprove} disabled={loadingApprove}>
          Approve
        </Button>
      </>
      }
    >
      <div style={{ paddingTop: '24px' }}>
        <Grid container spacing={3}>
          {
            request.isLegalPerson ?
              <Grid item xs={12}>
                <Typography sx={{ fontSize: '16px', marginBottom: '8px', color: '#7A858F' }}>
                  Are you sure you want to approve the participation request for project {request.project.title}?
                </Typography>
              </Grid>
              :
              <>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id={'Contract'}>{templateSelectLabel}</InputLabel>
                    <Select fullWidth
                            label={templateSelectLabel}
                            labelId={'Contract'}
                            value={selectedTemplate}
                            onChange={(event: SelectChangeEvent) => {
                              setSelectedTemplate(event.target.value);
                            }}
                    >
                      {
                        helloSignTemplates.length > 0 && helloSignTemplates.map((template: any, index: number) => (
                          <MenuItem key={index} value={template.templateId}>
                            <Typography>{template.title}</Typography>
                          </MenuItem>)
                        )
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: '14px', marginBottom: '8px', color: '#7A858F' }}>
                    Please upload the Schedule 5 for
                    this {status === ParticipationRequestStatusEnum.Novation ? 'Novation letter' : 'Contract'}
                  </Typography>
                  <div className="dropzone" {...getRootProps({ style: dropZoneStyle })}>
                    <input {...getInputProps()} />
                    <div>{getIcon('upload')}</div>
                    {
                      files.length > 0 ?
                        <div>{files[0].name}</div>
                        :
                        <Typography>Drop your image here, or <span
                          style={{ color: '#4A4DF0' }}>browse</span></Typography>
                    }
                  </div>
                </Grid>
              </>
          }
        </Grid>
      </div>
    </Modal>

    <Panel>
      <Grid container>
        <Grid item xs={12} sx={{ marginBottom: '32px' }}>
          <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
            Tokens Allocation
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {authContext.isAdmin && [ParticipationRequestStatusEnum.Pending, ParticipationRequestStatusEnum.Novation].includes(status) ? editMode : viewMode}
            </Grid>
            {
              !isPhone && <Grid item sm={1}>
                <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
                  <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
                </div>
              </Grid>
            }
            <Grid item xs={12} sm={5}>
              <Grid container spacing={1}>
                {
                  isPhone && <Grid item xs={12}>
                    <hr style={{ margin: '28px 0px 16px 0', border: '1px solid #d6d6d6', borderBottom: 'none' }}/>
                  </Grid>
                }
                <Grid item xs={6}>
                  <span style={{ fontWeight: 600, color: '#E2507A' }}>Total value</span>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="viewModeValue" color="#E2507A" component="span">
                    {
                      numberFormat(
                        allocations.reduce(
                          (sum, allocation) => sum + parseFloat(allocation.investment || '0'), 0
                        )
                      )} {request.investmentCurrency}
                  </Typography>
                </Grid>
                {(contract && allocations.length > 0) ?
                  <>
                    <Grid item xs={6}>
                      <span style={{ fontWeight: 600 }}>Last approved</span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue" component="span">
                        {numberFormat(
                          latestAllocations.reduce(
                            (sum, allocation) => sum + parseFloat(allocation.investment || '0'), 0
                          )
                        )} {request.investmentCurrency}
                      </Typography>
                    </Grid>
                  </>
                  :
                  <>
                    <Grid item xs={6}>
                      <span style={{ fontWeight: 600 }}>Requested</span>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue" component="span">
                        {numberFormat(request.investmentAmount)} {request.investmentCurrency}
                      </Typography>
                    </Grid>
                  </>
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {authContext.isAdmin && allocations.length > 0 &&
          <Grid item md={12} xs={12}>
            {
              !contract?.isComplete
                ? // Contract does not exist or not counter-signed yet.
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '64px' }}>
                  {
                    status === ParticipationRequestStatusEnum.Pending
                      ? // Contract does not exist at this point.
                      <>
                        <Button variant="outlined" onClick={handleDecline} color="error">Decline Request</Button>
                        <Button variant="contained" onClick={handleApprove}>Approve</Button>
                      </>
                      : // Contract is not counter-signed yet.
                      <Button variant="outlined" sx={{ width: { xs: '100%', md: 'auto' } }} onClick={handleWithdraw}
                              color="error">
                        Withdraw Approval
                      </Button>
                  }
                </Box>
                : // Contract is counter-signed, check Novation flow actions.
                status === ParticipationRequestStatusEnum.Approved
                  ? // Last Contract or Novation was approved at this point.
                  <>
                    {(novations?.length && !novations[novations.length - 1].isComplete) ?
                      <Box sx={{ display: 'flex', justifyContent: 'left', marginTop: '64px' }}>
                        <Button variant="outlined" sx={{ width: { xs: '100%', md: 'auto' } }} onClick={handleWithdraw}
                                color="error">Withdraw Approval</Button>
                      </Box> : ''
                    }
                    {!novations?.length && // Do not allow multiple novation letters for now.
                      <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '64px' }}>
                        <Button variant="contained" sx={{ width: { xs: '100%', md: 'auto' } }}
                                onClick={handleNovation}>Novation</Button>
                      </Box>
                    }
                  </>
                  : // Edit mode for novation (status: ParticipationRequestStatusEnum.Novation)
                  <Box sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', sm: 'row' },
                    justifyContent: 'space-between',
                    marginTop: '64px'
                  }}>
                    <Button variant="outlined" sx={{ marginBottom: { xs: '16px', sm: 0 } }}
                            onClick={handleCancelNovation}
                            color="error">
                      Cancel Novation
                    </Button>
                    <Button variant="contained" onClick={handleApprove}>Approve New Terms</Button>
                  </Box>
            }
          </Grid>
        }
      </Grid>
    </Panel>
  </Box>;
};
