import React from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { FilterOperatorEnum, FilterTypeEnum } from "../../../../../store/generated-models";

export const CheckboxFilter = (props: any) => {
  const { config, onChange, value: filterValue } = props;
  let checkboxValue = filterValue && filterValue.length
    ? filterValue[0]?.value
    : [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = checkboxValue.indexOf(event.target.name);
    if (event.target.checked) {
      if (index < 0) { // Ensure no duplicates.
        checkboxValue.push(event.target.name);
      }
    } else {
      if (index >= 0) { // Ensure value exists.
        checkboxValue.splice(index, 1);
      }
    }
    onChange([{ operator: FilterOperatorEnum.In, type: FilterTypeEnum.String, value: checkboxValue }]);
  };

  return <FormGroup onChange={handleChange}>
    {
      config.options.map((option: any) => <React.Fragment key={option.name}>
          <FormControlLabel
            control={<Checkbox checked={checkboxValue.indexOf(option.name) >= 0 || false}/>}
            name={option.name} label={option.label}/>
        </React.Fragment>
      )
    }
  </FormGroup>;
};
