import { Slider } from '@mui/material';
import React from 'react';
import { NumberInput } from '../NumberInput';
import { FilterOperatorEnum, FilterTypeEnum } from '../../../../../store/generated-models';
import { numberFormat } from '../../../../../core/helpers/utils';
import { styled } from '@mui/material/styles';
import './RangeFilter.scss';

const RangeSlider = styled(Slider)(({ theme }) => ({
  height: 2,
  padding: '2px 0',
  '& .MuiSlider-track': {
    border: 0
  },
  '& .MuiSlider-root': {

  },
  '& .MuiSlider-thumb': {
    height: 8,
    width: 8,
    '& ::after': {

    }
  }
}));

export const RangeFilter = (props: any) => {
  const { onChange, config, value: filterValue } = props;
  const [valueFrom, valueTo] = filterValue && filterValue.length
    ? [filterValue[0]?.value[0] || config.range[0], filterValue[1]?.value[0] || config.range[1]]
    : config.range;

  const handleChange = (event: any, newValue: number | number[]) => {
    /* TODO @SergeyKolesnikov: check if FROM is greater than TO and vice versa */
    // console.log('RangeFilter.handleChange:newValue', newValue);
    const newFilterValue = newValue instanceof Array ? [
      {
        operator: FilterOperatorEnum.Gte,
        type: FilterTypeEnum.Number,
        value: [newValue[0] != config.range[0] ? String(newValue[0]) : null]
      },
      {
        operator: FilterOperatorEnum.Lte,
        type: FilterTypeEnum.Number,
        value: [newValue[1] != config.range[1] ? String(newValue[1]) : null]
      }
    ] : null; // One-handed filter is not supported for the slider controls.
    onChange(newFilterValue);
  };

  return <>
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <NumberInput value={valueFrom} name="valueFrom"
                   precision={0}
                   onChange={(event: any, from: any) => handleChange(event, [from, valueTo])}
                   InputProps={{
                     inputProps: { style: { padding: '8px' } }
                   }}
      />
      <span style={{ margin: '0 4px' }}>-</span>
      <NumberInput value={valueTo} name="valueTo"
                   precision={0}
                   onChange={(event: any, to: any) => handleChange(event, [valueFrom, to])}
                   InputProps={{
                     inputProps: { style: { padding: '8px' } }
                   }}
      />
    </div>
    <div style={{ width: '100%' }}>
      <RangeSlider
        getAriaLabel={() => 'Temperature range'}
        value={[parseInt(valueFrom), parseInt(valueTo)]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        defaultValue={config.range}
        max={100000}
      />
      <div className="marks">
        <span>{`${numberFormat(config.range[0])} $`}</span>
        <span>{`${numberFormat(config.range[1])} $`}</span>
      </div>
    </div>
  </>;
};
