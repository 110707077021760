import React, { useContext, useState } from 'react';
import { AccountType, LoginResult, MutationSignupArgs, UserMode } from '../../../../store/generated-models';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { ConfirmName } from '../ConfirmName';
import Grid from '@mui/material/Grid';
import { Box, Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import styled from 'styled-components';
import { createStyles } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { gqlSignup } from '../../../../core/providers/gql';
import { Password } from '../../../../core/ui/text-fields/Password';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';

const useStyles: any = makeStyles(() =>
  createStyles({
    noMaxWidth: {
      maxWidth: 'none'
    }
  })
);

const FormControlLabel = styled(MuiFormControlLabel)`
  .MuiTypography-root {
    font-size: 12px;
  }
`;

const TooltipText = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  margin-bottom: 28px;
  color: #4A4DF0;
  text-decoration: none;
  font-size: 12px;
  
  &:hover {
    opacity: 0.87;
  }
`;

export const ProvideInfo: React.FC<any> = (props) => {
  const { accountType, setModalMessage } = props;
  const [isConfirmNameStep, setIsConfirmNameStep] = useState(false);
  const [requestIsProcessing, setRequestIsProcessing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [name, setName] = useState('');
  const [termsOfUse, setTermsOfUse] = useState(true);

  const [token, setToken] = useState(null);
  // const [givenReferralCode, setGivenReferralCode] = useState('');

  const authContext = useContext(AuthContext);
  const classes = useStyles();

  const [signup] = useMutation<{ signup: LoginResult }, MutationSignupArgs>(
    gqlSignup
  );

  const onStepOneFormSubmit = (e: any) => {
    e.preventDefault();

    if (!email || !password) {
      authContext.showMessage('error', 'Enter email and password to continue');
      return;
    }

    if (password !== passwordConfirmation) {
      authContext.showMessage(
        'error',
        'Password and password confirmation do not match'
      );
      return;
    }

    if (!termsOfUse) {
      authContext.showMessage(
        'error',
        'You need to agree with the terms and conditions'
      );
      return;
    }

    if (
      !/(?=.*[0-9])(?=.*[!@#$%^&*()_+}{":;?./><,-])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*()_+}{":;?./><,-]{6,}/g.test(
        password
      )
    ) {
      authContext.showMessage(
        'error',
        'New password must contain at least 6 characters, uppercase and lowercase letters, a number and a special character'
      );
      // const prmErrMsg = 'Incorrect password';
      // setErrors({...errs, newPassword: prmErrMsg});
      // showSnackbarErr('New password must contain at least 6 characters, uppercase and lowercase letters, a number and a special character', {autoHideDuration: 4000});
      return;
    }

    authContext
      .updateRecaptcha()
      .then((recaptchaToken) => {
        signup({
          variables: {
            email: email,
            password: password,
            firstName: firstName,
            lastName: lastName,
            termsOfUse: termsOfUse,
            recaptcha: recaptchaToken,
            // givenReferralCode: givenReferralCode
            mode: UserMode.StandardUser,
            accountType: accountType
          }
        })
          .then((res: any) => {
            setModalMessage(
              'Please confirm your email. Follow the instructions in the message that we have sent to your email.'
            );
          })
          .catch((error: any) => {
            const errorCode = error.graphQLErrors[0].extensions.code;
            if (errorCode === 'auth.password_invalid') {
              authContext.showMessage(
                'error',
                'Password should be at least 6 symbols long and contain at least one letter (lower- and uppercase), number and special symbol'
              );
            } else if (errorCode === 'auth.user_already_exists') {
              authContext.showMessage('error', 'User already exists');
            } else if (errorCode === 'auth.referral_code_invalid') {
              authContext.showMessage('error', 'Referral code is invalid');
            } else {
              authContext.showMessage('error', 'Unknown error occurred');
            }
          });
      })
      .catch(() => {
        console.warn('gr promise error');
      });
  };

  if (!isConfirmNameStep) {
    return (
      <>
        <Typography component="span" sx={{ fontWeight: 600, fontSize: '20px', marginBottom: '40px' }}>
          Put your personal info
        </Typography>
        <form onSubmit={onStepOneFormSubmit} autoComplete="new-password">
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="First Name"
                value={firstName}
                disabled={requestIsProcessing}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Last Name"
                value={lastName}
                disabled={requestIsProcessing}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Email"
                autoComplete="email"
                value={email}
                disabled={requestIsProcessing}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                // arrow
                placement="top-start"
                enterDelay={200}
                leaveDelay={200}
                classes={{ tooltip: classes.noMaxWidth }}
                title={
                  <TooltipText>
                    <div>Passwords must be at least 6 characters</div>
                    <div>Passwords must have at least one non alphanumeric character</div>
                    <div>Passwords must have at least one lowercase('a'- 'z')</div>
                    <div>Passwords must have at least one uppercase('A' - 'Z')</div>
                  </TooltipText>
                }
              >
                <div>
                  <Password
                    fullWidth
                    label="Password"
                    autoComplete="new-password"
                    value={password}
                    disabled={requestIsProcessing}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Password
                fullWidth
                label="Password confirmation"
                autoComplete="new-password"
                value={passwordConfirmation}
                disabled={requestIsProcessing}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={termsOfUse}
                    onChange={(e) => setTermsOfUse(e.target.checked)}
                  />
                }
                label={
                  <span style={{ fontSize: '12px' }}>
                    I agree to the{' '}
                    <StyledLink to="https://defiedtrust.com/terms" target="_blank">
                      Terms and conditions
                    </StyledLink>
                  </span>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size={'large'}
                disabled={
                  requestIsProcessing ||
                  !email ||
                  !password ||
                  !passwordConfirmation ||
                  !termsOfUse ||
                  !firstName ||
                  !lastName
                }
              >
                {!requestIsProcessing ? `Sign up` : 'Signing up...'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </>
    );
  } else {
    return <ConfirmName name={name} token={token}/>;
  }
};
