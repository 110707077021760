import { getIcon } from '../CustomIcon';
import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import useDebounce from '../../../hooks/useDebounce';
import { ScreenContext } from '../../../../../core/providers/ScreenProvider';

export const Search = (props: any) => {
  const { placeholder, onChange, field, fullWidth } = props;
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const [search, setSearch] = useState({});

  const onSearchChange = (event: any) => {
    const filter = {
      field: field || 'search',
      fieldFilters: {
        'operator': 'like',
        'type': 'string',
        'value': [event.target.value]
      }
    };
    setSearch(filter);
  };

  const debouncedSearch = useDebounce(search, 700);
  useEffect(() => {
    onChange(debouncedSearch);
  }, [debouncedSearch]);

  const getMinWidth = () => {
    let width = '352px';

    if (isTablet) {
      width = '150px';
    }
    if (isPhone) {
      width = '100%'
    }

    return width;
  }

  return <TextField placeholder={placeholder}
                    onChange={onSearchChange}
                    variant="outlined"
                    size="small"
                    sx={{ width: getMinWidth(), marginLeft: '12px', backgroundColor: 'white' }}
                    InputProps={{
                      startAdornment: getIcon('search'),
                      sx: {height: isPhone ? '42px' : '37px'}
                    }}
                    fullWidth={fullWidth}
  />;
};
