import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {Toolbar as MuiToolbar, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {ScreenContext} from '../../../../../core/providers/ScreenProvider';
import {NavigationContext} from '../../../../../core/components/BrowserRouter';
import {AccountInfo} from '../../common/business/AccountInfo/AccountInfo';
import LogoImage from '../../../../../assets/logo-v2.png';

export const Toolbar = (props: any) => {
  const {handleDrawerOpen, open} = props;
  const screenContext = useContext(ScreenContext);
  const navigationContext = useContext(NavigationContext);

  const {isTablet, isMobile} = screenContext;
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    setPageName(navigationContext.pageName);
  }, [navigationContext.pageName]);

  const toolbarStyle = isMobile ? {
    ...(open && {width: 'calc(100% - 200px)', alignSelf: 'end'}),
    ...(isTablet && {borderLeft: '1px solid #7A858F'}),
  } : {
    width: 'calc(100% - 240px)',
    alignSelf: 'end'
  };

  return (
    <MuiToolbar style={toolbarStyle}>
      {
        isMobile && <IconButton
          color="inherit"
          onClick={handleDrawerOpen}
          sx={{
            color: isMobile ? '#fff' : '#000000',
            marginRight: '16px',
            ...(open && {display: 'none'})
          }}
        >
          <MenuIcon/>
        </IconButton>
      }
      <div style={{display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
        {
          pageName &&
          <Typography variant={isMobile ? 'h6' : 'h4'} noWrap component="div" style={{fontWeight: 600}}>
            {pageName}
          </Typography>
        }
        {
          isMobile ? <img src={LogoImage} style={{width: '104px', marginLeft: isMobile ? '16px' : '32px'}}/> : <AccountInfo/>
        }
      </div>
    </MuiToolbar>
  );
};
