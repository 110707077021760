import React, { PropsWithChildren } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { concatClassNames } from '../../../../core/helpers/utils';
import './RichText.scss';

interface ClassProps {
  text: string;
  onChange: (text: any) => void;
  error?: boolean;
}

export const RichText = (props: PropsWithChildren<ClassProps>) => {
  const { text, onChange, error } = props;

  const handleChange = (value: string) => {
    onChange(value);
  };

  const classNames = concatClassNames({
    'rich-editor--error': error
  });

  return (
    <ReactQuill
      className={`rich-editor ${classNames}`}
      theme="snow"
      value={text}
      onChange={handleChange}
    />
  );
};
