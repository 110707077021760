import React, { Context } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { StylesProvider } from '@mui/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

const fontPrimary = 'Poppins, sans-serif';

// TODO: set up the common theme attributes here. Extend further with the light/dark theme-specific settings
// const themeBase = {};

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    viewModeLabel: true;
    viewModeValue: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  viewModeLabel: React.CSSProperties;
  viewModeValue: React.CSSProperties;
}

interface ExtendedTheme extends Theme {
  custom: {
    palette: {
      [attribute: string]: string
    }
  };
}

const applyOverrides = (theme: Theme): ExtendedTheme => {
  const extendedTheme = theme as ExtendedTheme;

  extendedTheme.custom = {
    palette: {}
  };

  return extendedTheme;
};

// @ts-ignore
const lightTheme = applyOverrides(createTheme({
  /*breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 992,
      lg: 1280,
      xl: 1920
    }
  },*/
  components: {
    // MuiButton: {
    //   styleOverrides: {
    //     root: {
    //       "&.MuiButton-outlinedError": {
    //         color: "#F97298",
    //         borderColor: "#F97298",
    //         "&:hover": {
    //           borderColor: "#f19bbf",
    //         }
    //       }
    //     }
    //   }
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.icon-select .social': {
            color: '#BE2954',
            height: '20px'
          }
        }
      }
    }
    /*MuiFormHelperText: {
      styleOverrides: {
        "root": {
          "&.Mui-error": {
            "color": "#BE2954"
          }
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        "root": {
          "&.Mui-error": {
            color: "#BE2954"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          "&.Mui-error": {
            color: "#BE2954"
          }
        }
      }
    },*/
    /*    MuiOutlinedInput: {
          styleOverrides: {
            "root": {
              notchedOutline: {
                borderColor: "#BE2954"
              }
            }
          }
        }*/
  },
  typography: {
    fontFamily: fontPrimary,
    body1: {
      fontWeight: 400,
      fontSize: '14px'
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '14px'
    },
    subtitle2: {
      fontWeight: 700,
      fontSize: '12px'
    },
    viewModeLabel: {
      marginBottom: '12px',
      fontSize: '12px',
      fontWeight: 600,
      textTransform: 'uppercase'
    },
    viewModeValue: {
      fontSize: '14px',
      fontWeight: 400
    }
  } as ExtendedTypographyOptions,
  palette: {
    primary: {
      main: '#4A4DF0'
    },
    primaryLight: {
      main: '#fff',
      contrastText: '#7A858F'
    },
    error: {
      main: '#E2507A'
    },
    success: {
      main: '#D6F5EE'
    },
    warning: {
      main: '#FEF6CA'
    },
    text: {
      secondary: '#BBBDC0'
    }
  }
}));

declare module '@mui/material/styles' {
  interface Palette {
    primaryLight: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    primaryLight?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    primaryLight: true;
  }
}

export interface ThemeContextProps {
  isDarkMode: boolean,
  setDarkMode: (value: boolean) => void
}

export const ThemeContext: Context<ThemeContextProps> = React.createContext(null);

export const AppThemeProvider: React.FC = ({ children }) => {

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={lightTheme}>
        <StyledComponentsThemeProvider theme={lightTheme}>
          {children}
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};
