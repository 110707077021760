import React, { useContext } from 'react';
import styled from 'styled-components';
import LogoImage from '../../../assets/logo-v2.png';
import { ScreenContext } from '../../../core/providers/ScreenProvider';

const Component = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  padding: 32px;
  background-color: #F5F5F5;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
`;

export const Auth: React.FC<any> = (props) => {
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  let padding = 56;
  if (isMobile) {
    padding = 32;
  }

  const Dialog = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #fff;
    padding: ${padding}px;
    border-radius: 8px;
  `;

  return (
    <Component>
      <Wrapper>
        <img src={LogoImage} style={{ width: '200px', marginBottom: '32px' }}/>
        <Dialog style={{ marginBottom: props.additionalInfo ? '40px' : 0 }}>
          {props.children}
        </Dialog>
        <div>
          {props.additionalInfo}
        </div>
      </Wrapper>
    </Component>
  );
};
