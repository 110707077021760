import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button, FormControlLabel,
  Grid, Link, Switch,
  Typography
} from '@mui/material';
import '../../../../../components/common/business/History/Transaction.scss';
import './CurrentSAFT.scss';
import { AuthContext } from '../../../../../../../core/providers/AuthProvider';
import { getIcon } from '../../../../../components/common/CustomIcon';
import { Panel } from '../../../../../components/common';
import { useMutation } from '@apollo/client';
import { copyTextToClipboard } from '../../../../../../../core/helpers/Clipboard';
import { useSnackbar } from 'notistack';
import { SET_FUNDS_RECEIVED } from '../../../../../../../store/mutations/participationRequest';
import { ScreenContext } from '../../../../../../../core/providers/ScreenProvider';

export const CurrentSAFT = (props: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const authContext = useContext(AuthContext);
  const { request, documentSignModule } = props;
  const { documentsViewData, fundsReceived, setFundsReceived } = documentSignModule;
  const { rollingFund, contract } = request;
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const data = {
    transactionId: contract.transactionId,
    transactionIdShort: contract.transactionIdShort,
    transactionUrl: contract.transactionUrl,
    buyerWalletAddress: contract.buyerWalletAddress,
    buyerWalletShort: contract.buyerWalletShort,
    buyerWalletUrl: contract.buyerWalletUrl,
    contributionWallet: rollingFund.contributionWallet,
    contributionWalletShort: rollingFund.contributionWalletShort,
    contributionWalletUrl: rollingFund.contributionWalletUrl,
    signatures: {
      buyer: {
        signed: contract.signedByBuyer !== null,
        date: contract.signedByBuyer
      },
      seller: {
        signed: contract.signedBySeller !== null,
        date: contract.signedBySeller
      }
    }
  };

  const CopyButton = (props: any) => {
    const { value } = props;

    return <>
      <Button onClick={() => copyTextToClipboard(value)}>
        {getIcon('copy')}
      </Button>
    </>;
  };

  const SAFTLink = (props: any) => {
    const { label, url, value, valueToDisplay } = props;

    return <>
      <Grid item xs={12} md={5}>
        <div>
          <Typography variant="viewModeLabel" color="text.secondary" component="span">
            {label}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12} md={7}>
        <div className="saft-link">
          {value ?
            <>
              <Link href={url} target="_blank" underline="hover">
                <Typography variant="viewModeValue">
                  {valueToDisplay}
                </Typography>
              </Link>
              <div className="saft-link__actions">
                <CopyButton value={value}/>
              </div>
            </>
            :
            <Typography variant="viewModeValue">N/A</Typography>
          }
        </div>
      </Grid>
    </>;
  };

  useEffect(() => {
    setFundsReceived(contract.fundsReceived);
  }, [contract]);

  const [updateFunds, {
    loading: loadingFunds
  }] = useMutation(SET_FUNDS_RECEIVED);
  const handleFundsReceived = async (event: any) => {
    setFundsReceived(event.target.checked);
    await updateFunds({
      variables: {
        contractId: request.contract.contractId,
        value: event.target.checked
      }
    }).then(() => {
      enqueueSnackbar('Saved', { variant: 'success' });
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    });
  };

  const userIsSeller = authContext.isAdmin
    || authContext.isFundOwner && contract?.sellerId === authContext.user.userId;

  return <>
    <Panel isLoading={documentSignModule.isRefetching}>
      <Grid container spacing={{ xs: 2 }}>
        <Grid item xs={12} sx={{ marginBottom: '32px' }}>
          <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500 }}>
            Current Contract
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container rowSpacing={2}>
            {
              data && <>
                <SAFTLink label="Contribution wallet"
                          url={data.contributionWalletUrl}
                          value={data.contributionWallet}
                          valueToDisplay={data.contributionWalletShort}
                />
                <SAFTLink label="Transaction ID"
                          url={data.transactionUrl}
                          value={data.transactionId}
                          valueToDisplay={data.transactionIdShort}
                />
                <SAFTLink label="Buyer wallet Address"
                          url={data.buyerWalletUrl}
                          value={data.buyerWalletAddress}
                          valueToDisplay={data.buyerWalletShort}
                />
              </>
            }
            {
              userIsSeller && <>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    disabled={loadingFunds || contract.isComplete}
                    control={
                      <Switch name="status" checked={!!fundsReceived}
                              onChange={handleFundsReceived}
                      />
                    }
                    label="Funds received"
                  />
                </Grid>
              </>
            }
          </Grid>
        </Grid>
        {
          !isPhone && <Grid item sm={1}>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
              <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
            </div>
          </Grid>
        }
        <Grid item xs={12} sm={5}>
          <Grid container>
            {
              isPhone && <Grid item xs={12}>
                <hr style={{ margin: '8px 0px 16px 0', border: '1px solid #d6d6d6', borderBottom: 'none' }}/>
              </Grid>
            }
            <Grid item xs={12}>
              <div className="signatures">
                {contract && documentsViewData.saftView &&
                  <div className="signature" style={{ color: documentsViewData.saftView.labelColor }}>
                    <Link onClick={documentsViewData.saftView.actionHandler} underline="hover"
                          className="signature__link">{getIcon('file')} Contract
                    </Link><span className="signature__dot">•</span>{documentsViewData.saftView.label}
                  </div>
                }
              </div>
            </Grid>
          </Grid>
        </Grid>
        {documentsViewData.currentUserAction &&
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'right', marginTop: '0' }}>
              <Button variant="contained"
                      sx={{ width: { xs: '140px', md: 'auto' } }}
                      onClick={documentsViewData.currentUserAction.actionHandler}
              >{documentsViewData.currentUserAction.label}</Button>
            </Box>
          </Grid>
        }
      </Grid>
    </Panel>
  </>;
};
