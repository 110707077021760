import React, {useContext, useEffect, useState} from 'react';
import {MutationConfirmEmailArgs} from '../../../store/generated-models';
import {FormContent} from '../elements/FormContent';
import {AuthContext} from '../../../core/providers/AuthProvider';
import {Button} from  '@mui/material';
import styled from 'styled-components';
import {gql, useMutation} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';

const TextBlockWrap = styled.div`
  text-align: center;
  margin-bottom: 12px;
`;

export const ConfirmEmail: React.FC<any> = () => {
  const [state, setState] = useState('validating');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const {token} = useParams();

  const [confirmEmail] = useMutation<{ confirmEmail: boolean }, MutationConfirmEmailArgs>(gql`
      mutation ConfirmEmail(
          $token: String!,
          $recaptcha: String!
      ) {
          confirmEmail (
              token: $token,
              recaptcha: $recaptcha
          )
      }
  `);

  // Check if the confirmation authToken is provided and valid
  useEffect(() => {

    if (!!token) {
      authContext.updateRecaptcha()
        .then((recaptchaToken) => {
          confirmEmail({
            variables: {
              token: token,
              recaptcha: recaptchaToken
            }
          })
            .then((res) => {

              if (res.data.confirmEmail) {
                setState('confirmed');
              } else {
                setState('error');
              }
            })
            .catch(() => {
              setState('error');
            });
        });
    }
  }, []);


  /* ### RENDER ### */
  let dialogContent: JSX.Element = null;


  if (!token) {
    dialogContent = (
      <>
        <TextBlockWrap>
          You need email validation token to continue
        </TextBlockWrap>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => navigate('/login')}
        >
          Back to the homepage
        </Button>
      </>
    );
  } else {
    if (state === 'validating') {
      dialogContent = (
        <div>Validating confirmation...</div>
      );
    } else if (state === 'error') {
      dialogContent = (
        <>
          <TextBlockWrap>
            <p>Can't confirm email.</p>
            <p>Make sure you're using the latest received confirmation email.</p>
          </TextBlockWrap>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate('/login')}
          >
            Back to the homepage
          </Button>
        </>
      );
    } else if (state === 'confirmed') {
      dialogContent = (
        <>
          <TextBlockWrap>
            <p>Email confirmed successfully</p>
            <p>You can log in now</p>
          </TextBlockWrap>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => navigate('/login')}
          >
            Log in
          </Button>
        </>
      );
    }
  }

  return (
    <FormContent>
      {dialogContent}
    </FormContent>
  );
};
