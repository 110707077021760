import React, {useContext} from 'react';
import {AuthContext} from '../../../../../core/providers/AuthProvider';
import {ScreenContext} from '../../../../../core/providers/ScreenProvider';
import {SidebarMenuItem} from './SidebarMenuItem';
import './SidebarMenu.scss';
import {getIcon} from '../../common/CustomIcon';
import {AccountInfo} from '../../common/business/AccountInfo/AccountInfo';
import config from "../../../../../config";

export const SidebarMenu = (props: any) => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext);

  const {handleDrawerClose, menuItems} = props;
  const {isPhone, isTablet, isMobile} = screenContext;
  const menuItemsToDraw = menuItems || authContext.menuItems;

  const menuStyles = {
    ...(isTablet && {borderTop: '1px solid #7A858F'})
  };

  const commonItems = menuItemsToDraw.filter((item: any) => !item.isBottom);
  const bottomItems = menuItemsToDraw.filter((item: any) => item.isBottom);

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    authContext.logout();
  };

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  return (
    <div className="menu" style={menuStyles}>
      <ul className="menu__items">
        {commonItems && commonItems.map((item: any, index: number) => <SidebarMenuItem
          key={index} data={item} handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
        )}
      </ul>
      <div>
        {
          isMobile && <ul className="menu__items menu__items--bottom">
            <li>
              <AccountInfo/>
            </li>
            <li style={{paddingTop: '8px'}}>
              <hr style={{borderBottom: 'none'}}/>
            </li>
            {
              !isFullyVerified && <>
                {
                  !authContext.isVerified &&
                  <SidebarMenuItem
                    handleDrawerClose={handleDrawerClose} isPhone={isPhone}
                    data={{
                      icon: getIcon('verify'),
                      name: 'Verify KYC'
                    }}
                    onClick={(e: any) => {
                      if (authContext.blockpassWidget) {
                        authContext.blockpassWidget._onBtnClickHandler(e);
                      }
                    }}
                  />
                }
                {
                  authContext.isVerified &&
                  <SidebarMenuItem
                    handleDrawerClose={handleDrawerClose} isPhone={isPhone}
                    data={{
                      icon: getIcon('verify'),
                      name: 'Verify KYB'
                    }}
                    onClick={() => window.open(config.kybUrl, '_blank')}
                  />
                }
              </>
            }
            <SidebarMenuItem
              handleDrawerClose={handleDrawerClose} isPhone={isPhone} iconFill
              data={{
                icon: getIcon('sign_out'),
                name: 'Sign Out'
              }}
              onClick={onLogoutClick}
            />
          </ul>
        }
        <ul className="menu__items">
          {bottomItems && bottomItems.map((item: any, index: number) => <SidebarMenuItem
            key={index} data={item} handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
          )}
        </ul>
      </div>
    </div>
  );
};
