import React, { useContext, useState } from 'react';
import { AccountTypeSelection } from './SignUpSteps/AccountTypeSelection';
import { ProvideInfo } from './SignUpSteps/ProvideInfo';
import { AccountType } from '../../../store/generated-models';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { ScreenContext } from '../../../core/providers/ScreenProvider';
import { useNavigate } from 'react-router-dom';

const StepContent: Record<number, any> = {
  0: AccountTypeSelection,
  1: ProvideInfo
};

export const Signup: React.FC<any> = (props) => {
  const steps = ['', ''];
  const [activeStep, setActiveStep] = useState(0);
  const [accountType, setAccountType] = useState(AccountType.User);
  const [modalMessage, setModalMessage] = useState(null);
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);
  const navigate = useNavigate();

  function getStep(index = 0, props: Record<string, unknown> = {}) {
    const Step = StepContent[index];
    return <Step {...props}/>;
  }

  const stepStyles = (index: number) => ({
    display: isPhone && activeStep !== index ? 'none' : 'block',
    ...(isPhone && activeStep === 0 && { marginLeft: '25%' }),
    ...(isPhone && activeStep === steps.length - 1 && { marginRight: '25%' })
  });

  const stepProps: { completed?: boolean } = {};

  return <>
    {
      !modalMessage && <div style={{ width: '100%' }}>
        <Stepper activeStep={activeStep} sx={{ margin: '0 5%', marginBottom: '40px' }}>
          {
            steps.map((label, index) => {
              return (
                <Step key={index} {...stepProps} style={stepStyles(index)}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })
          }
        </Stepper>
      </div>
    }
    {!modalMessage && getStep(activeStep, { accountType, setActiveStep, setAccountType, setModalMessage })}
    {
      modalMessage && <>
        <div>{modalMessage}</div>
        <Button
          fullWidth
          style={{ marginTop: '24px', minWidth: '100px' }}
          variant="contained"
          color="primary"
          onClick={() => {
            setModalMessage(null);
            navigate('/login');
          }}
        >
          OK
        </Button>
      </>
    }
  </>;
};
