import {Avatar, Box, Button, Chip, Typography} from '@mui/material';
import avatarDefault from '../../../../../../assets/avatar-default.svg';
import React, {useContext} from 'react';
import {AuthContext} from '../../../../../../core/providers/AuthProvider';
import {ScreenContext} from '../../../../../../core/providers/ScreenProvider';
import {getIcon} from '../../CustomIcon';
import config from "../../../../../../config";

export const AccountInfo = () => {
  const authContext = useContext(AuthContext);
  const screenContext = useContext(ScreenContext);

  const {isMobile} = screenContext;

  const onLogoutClick = (e: any) => {
    e.preventDefault();
    authContext.logout();
  };

  const getKycStatus = () => {
    switch (authContext.user?.kycStatus) {
      case 'approved':
        return 'Verified';
      case 'waiting':
        return 'Pending';
      case 'rejected':
        return 'Rejected';
      default:
        return 'Not Verified';
    }
  };

  const getKycColor = () => {
    switch (authContext.user?.kycStatus) {
      case 'approved':
        return '#D6F5EE';
      case 'waiting':
        return '#FEF6CA';
      default:
        return '#FAE2E9';
    }
  };

  const getKycTextColor = () => {
    switch (authContext.user?.kycStatus) {
      case 'approved':
        return '#2BB596';
      case 'waiting':
        return '#E29F50';
      default:
        return '#E2507A';
    }
  };

  let name = authContext.user.email;
  if (authContext.user.lastName && authContext.user.lastName) {
    name = `${authContext.user.firstName} ${authContext.user.lastName}`;
  }

  const isFullyVerified = authContext.isAdmin || authContext.isVerified
    && (authContext.user?.kybStatus === 'approved' || !authContext.isFundOwner);

  return (
    <Box sx={{
      marginLeft: 'auto',
      alignItems: 'center',
      fontSize: '12px',
      display: 'flex'
    }}>
      {
        !isMobile && !isFullyVerified && <>
          {
            !authContext.isVerified && <Button
              variant="outlined"
              sx={{color: '#000000', borderColor: '#000000', marginRight: '24px'}}
              onClick={(e: any) => {
                if (authContext.blockpassWidget) {
                  authContext.blockpassWidget._onBtnClickHandler(e);
                }
              }}
            >
              Verify KYC
            </Button>
          }
          {
            authContext.isVerified && <Button
              variant="outlined"
              sx={{color: '#000000', borderColor: '#000000', marginRight: '24px'}}
              onClick={() => window.open(config.kybUrl, '_blank')}
            >
              Verify KYB
            </Button>
          }
        </>
      }
      <Avatar sx={{width: 32, height: 32, marginRight: '8px'}}
              src={authContext.user.avatar ? `${process.env.REACT_APP_API_URL_REST}${authContext.user.avatar}` : ''}
      >
        <img src={avatarDefault}/>
      </Avatar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: '16px'
        }}
      >
        <Typography sx={{fontSize: '12px', marginBottom: '4px', color: isMobile ? 'white' : 'black'}}>
          {name}
        </Typography>
        {authContext.isUser &&
          <Chip
            label={getKycStatus()}
            sx={{
              backgroundColor: getKycColor(),
              color: getKycTextColor(),
              width: '80px',
              height: '16px',
              fontSize: '9px'
            }}
          />
        }
      </Box>
      {
        !isMobile && <Button onClick={onLogoutClick} style={{minWidth: 'auto'}}>
          {
            getIcon('sign_out')
          }
        </Button>
      }
    </Box>
  );
};
