import React, { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PersonalInformation } from './steps';
import { Modal, Panel } from '../../components/common';
import { Box, Button, Typography } from '@mui/material';
import { useQuery } from '@apollo/client';
import { GET_PARTICIPATION_REQUEST_BY_ID } from '../../../../store/queries/requests';
import { ProjectPRequest } from '../../../../store/generated-models';

export const ChangeRequest: FC = () => {
  const { id } = useParams();
  const ref = useRef();
  const navigate = useNavigate();

  const { data, loading } = useQuery(GET_PARTICIPATION_REQUEST_BY_ID, {
    fetchPolicy: 'network-only',
    variables: {
      participationRequestId: id
    }
  });
  const [request, setRequest] = useState<ProjectPRequest>();

  useEffect(() => {
    if (!loading) {
      setRequest(data.getProjectPRequestById);
    }
  }, [data]);

  const handleCancel = (_e: MouseEvent<HTMLButtonElement>) => {
    navigate(-1);
  };

  const [isOpenChangeTermsDialog, setIsOpenChangeTermsDialog] = useState(false);
  const [loadingChangeTerms, setLoadingChangeTerms] = useState(false);
  const handleSave = (_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenChangeTermsDialog(true);
  };

  const handleAcceptChangeTerms = () => {
    setLoadingChangeTerms(true);
    // @ts-ignore
    ref.current.handleSendParticipationRequest().then(() => {
      setLoadingChangeTerms(false);
      navigate(-1);
    }).catch((e: any) => {
      setLoadingChangeTerms(false);
    });
  };

  return (
    <Box>
      <Modal
        open={isOpenChangeTermsDialog}
        title="Change Terms"
        renderActions={() => <>
          <Button onClick={() => setIsOpenChangeTermsDialog(false)} disabled={loadingChangeTerms}>No</Button>
          <Button onClick={handleAcceptChangeTerms} disabled={loadingChangeTerms}>Yes</Button>
        </>}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {`Are you sure you want to change terms of the participation request for project "${request?.project?.title}"?`}
          </Typography>
        </>
      </Modal>
      <Panel>
        {request &&
          <>
            <PersonalInformation ref={ref} request={request}/>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, paddingTop: '48px' }}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{ mr: 1 }}
              >
                Cancel
              </Button>
              <Box sx={{ flex: '1 1 auto' }}/>
              <Button
                variant="contained"
                onClick={handleSave}
              >
                Send request
              </Button>
            </Box>
          </>
        }
      </Panel>
    </Box>
  );
};
