import React, { useContext, useEffect, useState } from 'react';
import { MutationSetPasswordArgs } from '../../../store/generated-models';
import { SubmitButton } from '../elements/SubmitButton';
import { FormContent } from '../elements/FormContent';
import { WelcomeText } from '../elements/WelcomeText';
import { AuthContext } from '../../../core/providers/AuthProvider';
import TextField from '@mui/material/TextField';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Password } from '../../../core/ui/text-fields/Password';

// interface NewPasswordProps extends RouteComponentProps {
//   token?: string;
// }

export const SetPassword: React.FC<any> = () => {
  const authContext = useContext(AuthContext);
  const { token } = useParams();
  const navigate = useNavigate();
  const [dialogState, setDialogState] = useState<string>('input');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const [
    callSetPassword, {
      loading: setPasswordLoading,
      error: setPasswordError,
      data: setPasswordData
    }] = useMutation<{ setPassword: boolean }, MutationSetPasswordArgs>(gql`
    mutation setPassword(
      $password: String!,
      $recaptcha: String!,
      $token: String!
    ) {
      setPassword (
        password: $password,
        recaptcha: $recaptcha,
        token: $token
      )
    }
  `);

  // change password request processing
  useEffect(() => {
    if (setPasswordData) {
      if (setPasswordData.setPassword) {
        setDialogState('success');
      } else {
        authContext.showMessage('error',
          'Unknown error. Please try again later.');
      }
    } else {
      if (setPasswordError) {
        const errorCode = setPasswordError.graphQLErrors[0].extensions.code;

        if (errorCode === 'auth.password_invalid') {
          authContext.showMessage('error', 'Password should be at least 6 symbols long and contain at least one letter (lower- and uppercase), number and special symbol');
        } else {
          authContext.showMessage('error',
            'Unknown error. Please try again later.');
        }
      }
    }
  }, [setPasswordError, setPasswordData]); // eslint-disable-line

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    if (!password) {
      authContext.showMessage('error', 'Enter new password to continue');
      return;
    }

    if (password !== passwordConfirmation) {
      authContext.showMessage('error', 'Password and password confirmation do not match');
      return;
    }

    authContext.updateRecaptcha().then((recaptchaToken) => {
      callSetPassword({
        variables: {
          password: password,
          recaptcha: recaptchaToken,
          token: token
        }
      });
    }).catch(() => {
      console.warn('gr promise error');
    });
  };

  /* ### RENDER ### */
  let dialogContent: JSX.Element = null;

  if (!token) {
    dialogContent = (
      <FormContent>
        <div style={{ textAlign: 'center' }}>You need email validation token to continue</div>
        <SubmitButton onClick={() => {
          navigate('/login');
        }}>Back to the homepage</SubmitButton>
      </FormContent>
    );
  } else {
    if (setPasswordLoading) {
      dialogContent = (
        <FormContent>
          <div>Setting new password...</div>
        </FormContent>
      );
    } else {
      if (dialogState === 'input') {
        dialogContent = (
          <>
            <Typography component="span" sx={{ fontWeight: 600, fontSize: '20px', marginBottom: '8px' }}>
              Reset password
            </Typography>
            <Typography component="span" sx={{ fontSize: '14px', marginBottom: '40px', color: '#BBBDC0' }}>
              Enter the new password
            </Typography>
            <form onSubmit={onFormSubmit} autoComplete="new-password">
              <Password
                id="password"
                name="password"
                autoComplete="password"
                fullWidth
                label="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
              <Password
                id="password-confirmation"
                name="password-confirmation"
                autoComplete="new-password"
                fullWidth
                label="Password confirmation"
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                style={{ margin: '24px 0 24px 0' }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size={'large'}
              >Continue</Button>
            </form>
          </>
        );
      } else {
        if (dialogState === 'success') {
          if (setPasswordData) {
            dialogContent = (
              <>
                <WelcomeText>The new password was set successfully</WelcomeText>
                <FormContent>
                  <div style={{ textAlign: 'center', fontSize: '14px' }}>
                    <p>You can now log in using this password.</p>
                  </div>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size={'large'}
                    onClick={() => {
                      navigate('/login');
                    }}
                  >Log in</Button>
                </FormContent>
              </>
            );
          }
        }
      }
    }
  }

  return dialogContent;
};
