// Helper class that holds the current token. Is required to be accessible outside React component tree.

class TokenStorage {
  private accessToken: string;

  public getAccessToken() {
    return this.accessToken;
  }

  public setAccessToken(token: string) {
    this.accessToken = token;
  }

  public clearToken() {
    this.accessToken = null;
  }
}

export const tokenStorage = new TokenStorage();
