import { gql } from "@apollo/client";

const gqlProjectsCommon = `
  projectId
  description
  avatar
  title
  tokenName
  tokenSymbol
  whitepaperLink
  status
  tokenGenerationDate
  socials {
    profileName
    profileUrl
    href
    social {
      socialCode
      title
    }
  }
`;

const gqlProjects = gqlProjectsCommon + `
  rounds {
    projectRoundId
    title
    tokenCount
    price
  }
`;

const gqlProjectsWithSummary = gqlProjectsCommon + `
  roundsTotal
  tokensTotal
  rounds {
    projectRoundId
    title
    tokenCount
    price
    statistics {
      tokensSold
      tokensApproved
    }
  }
`;

export const GET_PROJECTS = gql`
  query GetProjects($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getProjects(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        ${gqlProjects}
      }
    }
  }
`;

export const GET_PROJECTS_WITH_SUMMARY = gql`
  query GetProjectsWithSummary($filterEx: [Filter], $orderBy: [OrderBy!]) {
    getProjectsWithSummary(filterEx: $filterEx, orderBy: $orderBy) {
      list {
        ${gqlProjectsWithSummary}
      }
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: String) {
    getProjectById(projectId: $projectId) {
      projectId
      description
      avatar
      title
      tokenName
      tokenSymbol
      whitepaperLink
      status
      tokenGenerationDate
      externalTemplateId
      contributionWallet
      rounds {
        projectRoundId
        title
        tokenCount
        price
      }
      socials {
        socialDataId
        profileName
        profileUrl
        href
        social {
          socialCode
          title
        }
      }
  }
}
`;
