import React, {useContext} from 'react';
import styled from 'styled-components';
import {
  Drawer,
  IconButton,
  List
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import LogoImage from '../../../../../assets/logo-v2.png';
import {SidebarMenu} from './SidebarMenu';
import {ScreenContext} from '../../../../../core/providers/ScreenProvider';

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  height: '64px'
}));


export const Sidebar = (props: any) => {
  const {isPhone, isTablet, isMobile} = useContext(ScreenContext);
  const {open, handleDrawerClose, drawerWidth} = props;
  const drawerVariant = isPhone ? 'temporary' : 'persistent';

  let drawerHeader = (<img src={LogoImage} style={{width: '136px', margin: '24px'}}/>);
  if (isTablet) {
    drawerHeader = (
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}
                    sx={{marginLeft: 'auto', marginRight: '24px'}}
        >
          <ChevronLeftIcon sx={{color: '#fff'}}/>
        </IconButton>
      </DrawerHeader>
    );
  }
  if (isPhone) {
    drawerHeader = (
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}
                    sx={{marginRight: 'auto', marginLeft: '24px'}}
        >
          <CloseIcon sx={{color: '#fff'}}/>
        </IconButton>
        <img src={LogoImage} style={{width: '104px', marginRight: '20px'}}/>
      </DrawerHeader>
    );
  }

  return (
    <Drawer
      sx={{
        width: open ? drawerWidth : 0,
        flexShrink: 0,
        backgroundColor: '#1E232E',
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : 0,
          boxSizing: 'border-box',
          backgroundColor: '#1E232E'
        }
      }}
      variant={drawerVariant}
      anchor="left"
      open={open}
      transitionDuration={0}
    >
      {drawerHeader}
      <List style={{display: 'flex', flex: 1, padding: 0}}>
        <SidebarMenu handleDrawerClose={handleDrawerClose} isPhone={isPhone}/>
      </List>
    </Drawer>
  );
};
