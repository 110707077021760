import React, {useContext, useRef, useState} from 'react';
import styled from 'styled-components';
import {LoginResult, MutationLoginArgs, OAuthProvider} from '../../../store/generated-models';
import {AuthContext} from '../../../core/providers/AuthProvider';
import {ConfirmName} from './ConfirmName';
import {Confirm2fa} from './Confirm2fa';
import TextField from '@mui/material/TextField';
import {Box, Button, Typography} from '@mui/material';
import {gqlLogin} from '../../../core/providers/gql';
import {Password} from '../../../core/ui/text-fields/Password';
import {tokenStorage} from '../../common/tokenStorage';
import {useMutation} from '@apollo/client';
import {Link} from 'react-router-dom';

const StyledLink = styled(Link)`
  display: block;
  margin-bottom: 28px;
  color: #4A4DF0;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  
  &:hover {
    opacity: 0.87;
  }
`;
const ForgotPasswordLink = styled(Link)`
  display: block;
  margin-bottom: 28px;
  color: #4A4DF0;
  text-decoration: none;
  font-size: 12px;
  font-weight: 500;

  &:hover {
    opacity: 0.87;
  }
`;

export const Login: React.FC<any> = (props) => {
  const [isConfirmNameStep, setIsConfirmNameStep] = useState(false);
  const [isConfirm2faStep, setIsConfirm2faStep] = useState(false);
  const [requestIsProcessing, setRequestIsProcessing] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [token, setToken] = useState(null);
  const [modalMessage, setModalMessage] = useState(null);
  const submitFormValues = useRef(null);

  const authContext = useContext(AuthContext);

  const [login] = useMutation<{ login: LoginResult }, MutationLoginArgs>(gqlLogin);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    setRequestIsProcessing(true);

    if (!email || !password) {
      authContext.showMessage('error', 'Enter email and password to continue');
      return;
    }

    submitFormValues.current = {
      email: email,
      password: password
    };

    authContext.updateRecaptcha()
      .then((recaptchaToken) => {
        login({
          variables: {
            email: submitFormValues.current.email,
            password: submitFormValues.current.password,
            recaptcha: recaptchaToken
          }
        })
          .then((res: any) => {
            const loginData = res.data.login;

            if (loginData.authToken && loginData.authTokenAction === 'TwoFactorAuth') {
              setToken(loginData.authToken);
              tokenStorage.setAccessToken(loginData.authToken);
              setIsConfirm2faStep(true);
            } else if (loginData.authToken && loginData.authTokenAction === 'ConfirmName') {
              // setName(loginData.user.name);
              setName(loginData.authTokenActionParam ? loginData.authTokenActionParam : '');
              setToken(loginData.authToken);
              setIsConfirmNameStep(true);
            } else {
              authContext.login(loginData);
            }

          })
          .catch((error: any) => {
            let errorCode = '';
            try {
              errorCode = error.graphQLErrors[0].extensions.code;
            } catch (ignored) {
            }

            if (errorCode === 'auth.access_denied' || errorCode === 'auth.login_invalid') {
              authContext.showMessage('error', 'Invalid credentials');
            } else if (errorCode === 'auth.unconfirmed_email') {
              setModalMessage('Please confirm your email. Follow the instructions in the message that we have sent to your email.');
            } else if (errorCode === 'auth.unconfirmed_device') {
              setModalMessage('You are trying to log in from an unknown device. For your security, please confirm the device using the link sent to your email.');
            } else if (errorCode === 'auth.password_has_to_be_changed') {
              setModalMessage('Your password needs to be changed. Please follow the instructions in the message that we have sent to your email.');
            } else {
              authContext.showMessage('error', 'Unknown error occurred');
            }
          })
          .finally(() => {
            setRequestIsProcessing(false);
          });
      })
      .catch(() => {
        console.warn('gr promise error');
      });
  };

  /*const oAuthLogin = (provider: OAuthProvider, token: string) => {
    setRequestIsProcessing(true);

    authContext.updateRecaptcha()
      .then((recaptchaToken) => {
        login({
          variables: {
            oAuthProvider: provider,
            oAuthToken: token,
            recaptcha: recaptchaToken
          }
        })
          .then((res) => {
            const loginData = res.data.login;
            if (loginData.authToken && loginData.authTokenAction === 'TwoFactorAuth') {
              setToken(loginData.authToken);
              setIsConfirm2faStep(true);
            } else if (loginData.authToken && loginData.authTokenAction === 'ConfirmName') {

              // setName(loginData.user && loginData.user.name ? loginData.user.name : '');
              setName(loginData.user && loginData.user.email ? loginData.user.email : '');

              setToken(loginData.authToken);
              setIsConfirmNameStep(true);
            } else {
              authContext.login(loginData);
            }
          })
          .catch((error) => {
            let errorCode = '';
            try {
              errorCode = error.graphQLErrors[0].extensions.code;
            } catch (ignored) {
            }

            if (errorCode === 'auth.access_denied' || errorCode === 'auth.login_invalid') {
              authContext.showMessage('error', 'Invalid credentials');
            } else if (errorCode === 'auth.unconfirmed_email') {
              setModalMessage('Your email is still not confirmed. Please follow the instructions in the message that we have sent to your email.');
            } else if (errorCode === 'auth.unconfirmed_device') {
              setModalMessage('You are trying to log in from an unknown device. For your security, please confirm the device using the link sent to your email.');
            } else {
              authContext.showMessage('error', 'Unknown error occurred');
            }
          })
          .finally(() => {
            setRequestIsProcessing(false);
          });
      })
      .catch(() => {
        console.warn('recaptcha promise error');
      });
  };*/

  /*  const googleLoginCallback = (response: any) => {
      if (response.tokenId) {
        oAuthLogin(OAuthProvider.Google, response.tokenId);
      } else {
        console.error('google login error');
      }
    };

    const facebookLoginCallback = (response: any) => {
      if (response.accessToken) {
        oAuthLogin(OAuthProvider.Facebook, response.accessToken);
      } else {
        console.error('facebook login error');
      }
    };*/

  if (modalMessage) {
    return (
      <>
        <div>{modalMessage}</div>
        <Button
          fullWidth
          style={{marginTop: '24px', minWidth: '100px'}}
          variant="contained"
          color="primary"
          onClick={() => setModalMessage(null)}
        >
          OK
        </Button>
      </>
    );
  } else if (isConfirm2faStep) {
    return (
      <Confirm2fa
        token={token}
      />
    );
  } else if (isConfirmNameStep) {
    return (
      <ConfirmName
        name={name}
        token={token}
      />
    );
  } else {
    /* Render form */
    return (
      <>
        <Typography component="span" sx={{fontWeight: 600, fontSize: '20px', marginBottom: '8px'}}>
          Welcome Back!
        </Typography>
        <Typography component="span" sx={{fontSize: '14px', marginBottom: '40px', color: '#BBBDC0'}}>
          Please enter your details
        </Typography>
        <form onSubmit={onFormSubmit} autoComplete="off">
          <TextField
            variant="outlined"
            fullWidth
            label="Email"
            autoComplete="email"
            value={email}
            disabled={requestIsProcessing}
            onChange={e => setEmail(e.target.value)}
          />
          <Password
            style={{margin: '24px 0 8px 0'}}
            fullWidth
            label="Password"
            autoComplete="password"
            value={password}
            disabled={requestIsProcessing}
            onChange={e => setPassword(e.target.value)}
          />
          <ForgotPasswordLink to="/forgot-password">Forgot password?</ForgotPasswordLink>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size={'large'}
            disabled={requestIsProcessing || !email || !password}
          >
            {!requestIsProcessing ? `Sign in` : 'Signing in...'}
          </Button>
        </form>
      </>
    );
  }
};
