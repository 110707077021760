import React, {useContext, useEffect, useState} from 'react';
import {FormContent} from '../elements/FormContent';
import {SubmitButton} from '../elements/SubmitButton';
import {
  MutationForgotPasswordArgs
} from '../../../store/generated-models';
import {WelcomeText} from '../elements/WelcomeText';
import {AuthContext} from '../../../core/providers/AuthProvider';
import TextField from  '@mui/material/TextField';
import {gql, useMutation} from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const ForgotPassword: React.FC<any> = () => {
  const [email, setEmail] = useState('');
  const [dialogState, setDialogState] = useState('input');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [forgotPassword, {
    loading: forgotPasswordLoading,
    error: forgotPasswordError,
    data: forgotPasswordData
  }] = useMutation<{ forgotPassword: boolean }, MutationForgotPasswordArgs>(gql`
      mutation ForgotPassword(
          $email: String!,
          $recaptcha: String!
      ) {
          forgotPassword (
              email: $email,
              recaptcha: $recaptcha
          )
      }
	`);

  // reset password request processing
  useEffect(() => {
    if (forgotPasswordData) {
      if (forgotPasswordData.forgotPassword) {
        setDialogState('success');
      } else {
        authContext.showMessage('error',
          'We couldn\'t send you the password resetting link. Please check the entered email or try again later');
      }
    } else {
      if (forgotPasswordError) {
        authContext.showMessage('error',
          'We couldn\'t send you the password resetting link. Please check the entered email or try again later');
      }
    }
  }, [forgotPasswordError, forgotPasswordData]); // eslint-disable-line

  const onFormSubmit = async (e: any) => {
    e.preventDefault();

    authContext.updateRecaptcha()
      .then((recaptchaToken) => {
        forgotPassword({
          variables: {
            email: email,
            recaptcha: recaptchaToken
          }
        });
      })
      .catch(() => {
        console.warn('gr promise error');
      });
  };

  let dialogContent: JSX.Element = null;

  if (forgotPasswordLoading) {
    dialogContent = (
      <div>Processing...</div>
    );
  } else {
    if (dialogState === 'input') {
      dialogContent = (
        <>
          <Typography component='span' sx={{fontWeight: 600, fontSize: '20px', marginBottom: '8px'}}>
            Forgot Password?
          </Typography>
          <Typography component='span' sx={{fontSize: '14px', marginBottom: '40px', color: '#BBBDC0'}}>
            Don’t worry, we’ll send you reset instruction.
          </Typography>

          <form onSubmit={onFormSubmit} autoComplete="new-password">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label="Enter your Email"
                  autoComplete="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size={'large'}
                >
                  Continue
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{display: 'flex'}}>
                  <Button startIcon={<ArrowBackIcon />} sx={{margin: '0 auto', fontSize: '14px', fontWeight: 500}}
                    onClick={() => navigate('/login')}>
                    Back to Log In
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </>
      );
    } else if (dialogState === 'success') {
      dialogContent = (
        <>
          <Typography component='span' sx={{fontWeight: 600, fontSize: '20px', marginBottom: '8px'}}>
            Check your inbox
          </Typography>
          <Typography component='span' sx={{fontSize: '14px', marginBottom: '40px', color: '#BBBDC0', textAlign: 'center'}}>
            Please follow the instructions in the message that we have sent to your Email
          </Typography>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size={'large'}
            onClick={() => navigate('/login')}
          >
            Back to Log In
          </Button>
        </>
      );
    }
  }

  return (
    <>
      {dialogContent}
    </>
  );
};
