import React from 'react';
import { ProtectedPage } from './bundles/common/components/ProtectedPage';
import { Auth } from './bundles/auth/components/Auth';
import { Private } from './bundles/private/Private';
import { Link, Route } from 'react-router-dom';
import { Login } from './bundles/auth/components/Login';
import { Signup } from './bundles/auth/components/Signup';
import { ForgotPassword } from './bundles/auth/components/ForgotPassword';
import { SetPassword } from './bundles/auth/components/SetPassword';
import { ConfirmEmail } from './bundles/auth/components/ConfirmEmail';
import { ConfirmDevice } from './bundles/auth/components/ConfirmDevice';
import { Box, Typography } from '@mui/material';
import styled from 'styled-components';

/*TODO: change token prop to useParam() hook params.token*/

const StyledLink = styled(Link)`
  margin-bottom: 28px;
  color: #4A4DF0;
  text-decoration: none;
  font-size: 12px;
  
  &:hover {
    opacity: 0.87;
  }
`;

const dontHaveAnAccount = <Box sx={{ display: 'flex' }}>
  <Typography component="span" sx={{ fontSize: '12px', color: '#7A858F', whiteSpace: 'pre' }}>
    {'Don’t have an account? '}
  </Typography>
  <StyledLink style={{ fontSize: '12px', fontWeight: '500' }} to="/signup">Sign up</StyledLink>
</Box>;

const alreadyHaveAnAccount = <Box sx={{ display: 'flex' }}>
  <Typography component="span" sx={{ fontSize: '12px', color: '#7A858F', whiteSpace: 'pre' }}>
    {'Already have an account? '}
  </Typography>
  <StyledLink style={{ fontSize: '12px', fontWeight: '500' }} to="/login">Sign in</StyledLink>
</Box>;

export const routes = [
  <Route path="/" key="auth">
    <Route index element={<ProtectedPage pageComponent={<Private/>}/>}/>
    <Route path="login" element={<Auth additionalInfo={dontHaveAnAccount}><Login/></Auth>}/>,
    <Route path="signup" element={<Auth additionalInfo={alreadyHaveAnAccount}><Signup/></Auth>}/>,
    <Route path="forgot-password" element={<Auth><ForgotPassword/></Auth>}/>,
    <Route path="new-password/:token" element={<Auth><SetPassword/></Auth>}/>,
    <Route path="confirm-email/:token" element={<Auth><ConfirmEmail/></Auth>}/>,
    <Route path="confirm-device/:token" element={<Auth><ConfirmDevice/></Auth>}/>,
  </Route>,
  <Route path="private/*" key="private" element={<ProtectedPage pageComponent={<Private/>}/>}/>
];
