import React, {useContext, useEffect, useState} from 'react';
import {MutationConfirmDeviceArgs} from '../../../store/generated-models';
import {FormContent} from '../elements/FormContent';
import {SubmitButton} from '../elements/SubmitButton';
import {AuthContext} from '../../../core/providers/AuthProvider';
import {gql, useMutation} from '@apollo/client';
import {useNavigate, useParams} from 'react-router-dom';
import {WelcomeText} from "../elements/WelcomeText";
import {Button} from "@mui/material";

export const ConfirmDevice: React.FC<any> = () => {
  const [state, setState] = useState('validating');
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  const [confirmEmail] = useMutation<{ confirmDevice: boolean }, MutationConfirmDeviceArgs>(gql`
      mutation ConfirmDevice(
          $token: String!,
          $recaptcha: String!
      ) {
          confirmDevice (
              token: $token,
              recaptcha: $recaptcha
          )
      }
  `);

  // Check if the confirmation authToken is provided and valid
  const {token} = useParams();
  useEffect(() => {
    if (!!token) {
      authContext.updateRecaptcha()
        .then((recaptchaToken) => {
          confirmEmail({
            variables: {
              token: token,
              recaptcha: recaptchaToken
            }
          })
            .then((res) => {
              if (res.data.confirmDevice) {
                setState('confirmed');
              } else {
                setState('error');
              }
            })
            .catch(() => {
              setState('error');
            });
        });
    }
  }, []);


  /* ### RENDER ### */
  let dialogContent: JSX.Element = null;


  if (!token) {
    dialogContent = (
      <>
        <div style={{textAlign: 'center'}}>You need device validation token to continue</div>
        <SubmitButton onClick={() => {
          navigate('/login');
        }}>Back to the homepage</SubmitButton>
      </>
    );
  } else {
    if (state === 'validating') {
      dialogContent = (
        <div>Validating confirmation...</div>
      );
    } else if (state === 'error') {
      dialogContent = (
        <>
          <WelcomeText>Couldn't confirm the new device</WelcomeText>
          <FormContent>
            <div style={{ textAlign: 'center', fontSize: '14px' }}>
              <p>Make sure you're using the latest received confirmation email.</p>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size={'large'}
              onClick={() => {
                navigate('/login');
              }}
            >Back to the homepage</Button>
          </FormContent>
        </>
      );
    } else if (state === 'confirmed') {
      dialogContent = (
        <>
          <WelcomeText>Device was confirmed successfully</WelcomeText>
          <FormContent>
            <div style={{ textAlign: 'center', fontSize: '14px' }}>
              <p>You can log in now.</p>
            </div>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size={'large'}
              onClick={() => {
                navigate('/login');
              }}
            >Log in</Button>
          </FormContent>
        </>
      );
    }
  }

  return (
    <FormContent>
      {dialogContent}
    </FormContent>
  );
};
