import React, { useEffect } from 'react';
import { getIcon } from '../components/common/CustomIcon';
import { useSnackbar } from 'notistack';

export const useKYBNotification = (isLegalPerson: any) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isLegalPerson) {
      enqueueSnackbar('Internal server error', {
        content: <div style={{
          display: 'flex',
          maxWidth: '360px',
          background: '#E4E5FD',
          borderRadius: '4px',
          padding: '14px 16px'
        }}>
          <div style={{ marginTop: '2px', marginRight: '12px' }}>{getIcon('info')}</div>
          <div>
            <div style={{ fontSize: '14px', fontWeight: 600, color: '#2A2DC5' }}>KYB verification</div>
            <div style={{ fontSize: '12px', fontWeight: 400, color: '#2A2DC5' }}>This is not an automated proces. Our
              team will contact you to inform the next steps.
            </div>
          </div>
        </div>,
        variant: 'info', anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  }, [isLegalPerson]);
};
