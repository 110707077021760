import React, { FC, MouseEvent, useCallback, useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Grid,
  Typography
} from '@mui/material';
import {
  ParticipationRequestStatusEnum,
  RollingFundPRequest
} from '../../../../../../../store/generated-models';
import { AuthContext } from '../../../../../../../core/providers/AuthProvider';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { dateFormat, numberFormat } from '../../../../../../../core/helpers/utils';
import { ScreenContext } from '../../../../../../../core/providers/ScreenProvider';
import { Panel, RequestStatusChip } from '../../../../../components/common';
import { Social } from '../../../../../components/common/Social';
import { ASSETS, INCOME, OCCUPATION, PERIOD_TYPES, SOURCE_OF_FUNDS } from '../../../enums';
import { useSnackbar } from 'notistack';
import { useDeclineModal } from './useDeclineModal';
import { useApproveModal } from './useApproveModal';
import { useWithdrawModal } from './useWithdrawModal';
import { useWithdrawApprovalModal } from './useWithdrawApprovalModal';
import { useChangeTermsModal } from './useChangeTermsModal';
import { CANCEL_NOVATION } from '../../../../../../../store/mutations/participationRequest';
import config from '../../../../../../../config';

type OwnProps = {
  request: RollingFundPRequest,
  refetch: any
};
type RequestSummaryProps = FC<OwnProps>;

export const RequestSummary: RequestSummaryProps = ({ request, refetch }) => {
  const { contract } = request;
  const { status } = request;
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);


  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const { enqueueSnackbar } = useSnackbar();

  const { modal: declineModal, setIsOpenDecline } = useDeclineModal(request);
  const { modal: approveModal, setIsOpenApprove } = useApproveModal(request, refetch);
  const { modal: withdrawModal, setIsOpenWithdraw } = useWithdrawModal(request);
  const { modal: withdrawApprovalModal, setIsOpenWithdrawApproval } = useWithdrawApprovalModal(request, refetch);
  const { modal: changeTermsModal, setIsOpenChangeTerms } = useChangeTermsModal(request);

  const handleDecline = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenDecline(true);
  }, []);
  const handleApprove = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenApprove(true);
  }, []);
  const handleWithdraw = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenWithdraw(true);
  }, []);
  const handleWithdrawApproval = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    setIsOpenWithdrawApproval(true);
  }, []);
  const handleChangeTerms = useCallback((_e: MouseEvent<HTMLButtonElement>) => {
    if (request.status === ParticipationRequestStatusEnum.Approved) {
      setIsOpenChangeTerms(true);
    } else {
      navigate(`/private/fund-change-request/${request.rollingFundPRequestId}`);
    }
  }, []);

  const [cancelNovation, { loading: loadingCancelNovation }] = useMutation(CANCEL_NOVATION);
  const handleCancelNovation = async () => {
    await cancelNovation({
      variables: {
        participationRequestId: request.rollingFundPRequestId
      }
    }).then(() => {
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };

  return (
    <>
      {declineModal}
      {approveModal}
      {withdrawModal}
      {withdrawApprovalModal}
      {changeTermsModal}

      <Panel>
        <Grid container>
          <Grid item xs={12} sx={{ marginBottom: '32px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 500, marginRight: '24px' }}>
                {request.rollingFund.title}
              </Typography>
              {
                request.status && <RequestStatusChip status={request.status} sx={{ width: '86px', fontSize: '12px' }}/>
              }
              {
                request.isLegalPerson && <Chip sx={{ fontSize: '12px', marginLeft: '16px' }} color="error" label="KYB"/>
              }
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Grid container>
                {
                  (authContext.isAdmin || authContext.isFundOwner) &&
                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          Investor name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="viewModeValue">
                          {request.user.fullName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                }

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Subscription amount
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {`${numberFormat(request.investmentAmount)} ${request.investmentCurrency}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Subscription period
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {request.period} {PERIOD_TYPES[request.periodType]}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Subscription Start Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {dateFormat(request.rollingFund.startDate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                  <Grid container columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="viewModeLabel" color="text.secondary" component="span">
                        Management Fee
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="viewModeValue">
                        {request.rollingFund.managementFee || 0}%
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {request.isLegalPerson &&
                  <Grid item xs={12} sx={{ marginBottom: '24px' }}>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={6}>
                        <Typography variant="viewModeLabel" color="text.secondary" component="span">
                          KYB verification
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Box sx={{ display: 'flex' }}>
                          <Social socialCode="link" href={config.kybUrl}/>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                }
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={0} sm={1}>
            <div style={{ display: 'flex', height: '100%', justifyContent: 'center' }}>
              <div style={{ width: '1px', borderLeft: '1px solid #D6D6D6' }}/>
            </div>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Grid container columnSpacing={1} sx={{ marginBottom: '24px' }}>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    Source of fund
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {SOURCE_OF_FUNDS[request.sourceOfFunds]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} sx={{ marginBottom: '24px' }}>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    Occupation/Business
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {OCCUPATION[request.occupation]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} sx={{ marginBottom: '24px' }}>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    INCOME PER YEAR
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {INCOME[request.income]}
                </Typography>
              </Grid>
            </Grid>

            <Grid container columnSpacing={1} sx={{ marginBottom: '24px' }}>
              <Grid item xs={5} md={4}>
                <Box>
                  <Typography variant="viewModeLabel" color="text.secondary" component="span">
                    ASSETS VALUE
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}/>
              <Grid item xs={6} md={7}>
                <Typography variant="viewModeValue">
                  {ASSETS[request.assets]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container columnSpacing={1}>
              <Grid item xs={6} sm={3}>
                <Typography variant="viewModeLabel" color="text.secondary" component="span">
                  Application message
                </Typography>
              </Grid>
              <Grid item xs={6} sm={9}>
                <Typography variant="viewModeValue">
                  {request.motivation}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {!contract?.isComplete && authContext.isUser && authContext.user.userId === request.user.userId &&
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: isPhone ? '32px' : '64px' }}>
                <Button variant="outlined"
                        sx={{ width: { xs: '140px', sm: '180px', md: 'auto' } }}
                        onClick={handleWithdraw} color="error"
                >{isPhone ? 'Withdraw' : 'Withdraw Request'}</Button>
                <Button variant="contained"
                        sx={{ width: { xs: '140px', md: 'auto' } }}
                        onClick={handleChangeTerms}
                >Change Terms</Button>
              </Box>
            </Grid>
          }
          {(authContext.isAdmin || authContext.isFundOwner) &&
            status !== ParticipationRequestStatusEnum.Declined && !contract?.isComplete &&
            <Grid item md={12} xs={12}>
              {
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '64px' }}>
                  {
                    status === ParticipationRequestStatusEnum.Pending
                      ? // Contract does not exist at this point.
                      <>
                        <Button variant="outlined" onClick={handleDecline} color="error">Decline Request</Button>
                        <Button variant="contained" onClick={handleApprove}>Approve</Button>
                      </>
                      : // Contract is not counter-signed yet.
                      <Button variant="outlined" sx={{ width: { xs: '100%', md: 'auto' } }}
                              onClick={handleWithdrawApproval}
                              color="error"
                      >
                        Withdraw Approval
                      </Button>
                  }
                </Box>
              }
            </Grid>
          }
        </Grid>
      </Panel>
    </>
  );
};
