import React from 'react';
import {getIcon} from './CustomIcon';
import Link from '@mui/material/Link';
import './Social.scss'

interface SocialProps {
  socialCode: string,
  href?: string
}

export const Social = (props: SocialProps) => {
  const {socialCode, href} = props;

  return <>
    {
      href ? <Link href={href}
                   onClick={(event) => event.stopPropagation()}
                   target="_blank"
        >
          <div className="social">{getIcon(socialCode)}</div>
        </Link>
        :
        <div className="social">{getIcon(socialCode)}</div>
    }
  </>;
};
