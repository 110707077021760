import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { Modal } from '../../../../../components/common';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  APPROVE_ROLLING_FUND_PARTICIPATION_REQUEST
} from '../../../../../../../store/mutations/participationRequest';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@mui/lab';
import { GET_HS_TEMPLATES } from '../../../../../../../store/queries/helloSign';
import { HsTemplateType, ParticipationRequestStatusEnum } from "../../../../../../../store/generated-models";

export const useApproveModal = (request: any, refetch: any) => {
  const { status } = request;
  const { enqueueSnackbar } = useSnackbar();

  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [startDate, setStartDate] = useState(request.rollingFund.startDate);
  const handleSubscriptionStartDate = (value: any) => {
    const isError = value == null;
    setStartDateError(isError);
    setStartDate(value);
  };

  const templateSelectLabel = status === ParticipationRequestStatusEnum.Novation ? 'Novation Template' : 'Contract Template';
  const { data: helloSignData, loading: helloSignDataLoading } = useQuery(GET_HS_TEMPLATES, {
    variables: { templateType: HsTemplateType.RollingFundPRequestContract },
    fetchPolicy: 'network-only'
  });

  const defaultTemplate = status !== ParticipationRequestStatusEnum.Novation ?
    request.rollingFund.externalTemplateId || '' :
    '';
  const [selectedTemplate, setSelectedTemplate] = useState(defaultTemplate);
  const [helloSignTemplates, setHelloSignTemplates] = useState([]);
  useEffect(() => {
    if (helloSignData) {
      setHelloSignTemplates(helloSignData.getHsTemplates);
    }
  }, [helloSignData]);
  useEffect(() => {
    if (!selectedTemplate && helloSignTemplates.length > 0) {
      setSelectedTemplate(helloSignTemplates[0].templateId);
    }
  }, [helloSignTemplates]);

  const [approveParticipationRequest, { loading: loadingApprove }] = useMutation(APPROVE_ROLLING_FUND_PARTICIPATION_REQUEST);
  const handleAcceptApprove = async () => {
    await approveParticipationRequest({
      variables: {
        participationRequestId: request.rollingFundPRequestId,
        startDate,
        externalTemplateId: selectedTemplate
      }
    }).then(() => {
      setIsOpenApprove(false);
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  };


  const modal = <Modal open={isOpenApprove} isLoading={helloSignDataLoading} title="Approve Request"
                       renderActions={() => <>
                         <Button disabled={loadingApprove}
                                 onClick={() => {
                                   setIsOpenApprove(false);
                                 }}
                         >
                           Cancel
                         </Button>
                         <Button onClick={handleAcceptApprove} disabled={loadingApprove}>
                           Approve
                         </Button>
                       </>
                       }
  >
    <div style={{ paddingTop: '24px' }}>
      <Grid container spacing={3}>
        {
          request.isLegalPerson ?
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '16px', marginBottom: '8px', color: '#7A858F' }}>
                Are you sure you want to approve the participation request for fund {request.rollingFund.title}?
              </Typography>
            </Grid>
            :
            <>
              <Grid item xs={12}>
                <Typography sx={{ marginBottom: '20px' }}>
                  {`Are you sure you want to approve the participation request for fund "${request.rollingFund.title}"?`}
                </Typography>

                <DatePicker
                  label="Subscription start date"
                  value={startDate}
                  onChange={handleSubscriptionStartDate}
                  renderInput={
                    (params) => <TextField required
                                           fullWidth={true} {...params}
                                           error={startDateError}
                                           sx={{ marginBottom: '20px' }}
                    />
                  }
                  PopperProps={{
                    placement: 'bottom-start',
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 8]
                        }
                      }
                    ]
                  }}
                  inputFormat="dd.MM.yyyy"
                  mask="__.__.____"
                />

                <FormControl fullWidth>
                  <InputLabel id={'Contract'}>{templateSelectLabel}</InputLabel>
                  <Select fullWidth
                          label={templateSelectLabel}
                          labelId={'Contract'}
                          value={selectedTemplate}
                          onChange={(event: SelectChangeEvent) => {
                            setSelectedTemplate(event.target.value);
                          }}
                  >
                    {
                      helloSignTemplates.length > 0 && helloSignTemplates.map((template: any, index: number) => (
                        <MenuItem key={index} value={template.templateId}>
                          <Typography>{template.title}</Typography>
                        </MenuItem>)
                      )
                    }
                  </Select>
                </FormControl>
              </Grid>
            </>
        }
      </Grid>
    </div>
  </Modal>;

  return {
    modal,
    setIsOpenApprove
  };
};
