import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { Modal } from '../../components/common';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';

export type OriginalData = {
  width: number,
  height: number,
  naturalWidth: number,
  naturalHeight: number
}

type CropImageModalProps = {
  isOpenCropDialog: boolean;
  setIsOpenCropDialog: (state: boolean) => void;
  handleCancel: () => void;
  handleSave: (crop: PercentCrop, completedCrop: PercentCrop, completedSpacing: CompletedSpacing, originalData: OriginalData, imageBitmap: ImageBitmap) => void;
  imgSrc: any;
}

type Spacing = {
  dxPercent?: number,
  dyPercent?: number,
}

export type CompletedSpacing = {
  leftSpace?: number,
  topSpace?: number,
  rightSpace?: number,
  bottomSpace?: number,
}

const StyledCrop = styled(ReactCrop)`
  .ReactCrop {
    flex: 1
  },
  .ReactCrop__child-wrapper {
    display: flex;
    flex: 1
  },
  .ReactCrop--circular-crop {
    .ReactCrop__crop-selection {
      box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgb(133 133 133 / 50%);
    }
  }
`;

export const CropImageModal: FC<CropImageModalProps> = (props: CropImageModalProps) => {
  const {
    isOpenCropDialog = false,
    setIsOpenCropDialog,
    handleCancel,
    handleSave,
    imgSrc
  } = props;
  const imgRef = useRef<HTMLImageElement>(null);
  const imgSpacedRef = useRef<HTMLImageElement>(null);
  const cnvRef = useRef<HTMLCanvasElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  const [spacing, setSpacing] = useState<Spacing>({});
  const [completedSpacing, setCompletedSpacing] = useState<CompletedSpacing>({});
  const [crop, setCrop] = useState<Crop>();
  const [isEmptyCrop, setIsEmptyCrop] = useState(false);
  const [originalCrop, setOriginalCrop] = useState<PercentCrop>();
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>();
  const [originalData, setOriginalData] = useState<OriginalData>();
  const [imgSpacedSrc, setImgSpacedSrc] = useState('');

  const onSave = async () => {
    if (isEmptyCrop) {
      enqueueSnackbar('Please select an area to crop', { variant: 'warning' });
    } else {
      const calculatedX = imgSpacedRef.current.width * (originalCrop.x / 100);
      const calculatedY = imgSpacedRef.current.height * (originalCrop.y / 100);
      const calculatedWidth = imgSpacedRef.current.width * (originalCrop.width / 100);
      const calculatedHeight = imgSpacedRef.current.height * (originalCrop.height / 100);

      const bitmap = await createImageBitmap(imgSpacedRef.current, calculatedX, calculatedY, calculatedWidth, calculatedHeight);

      handleSave(originalCrop, completedCrop, completedSpacing, originalData, bitmap);
    }
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    const { naturalWidth, naturalHeight } = e.currentTarget;

    centerCrop();
    setOriginalData({ width, height, naturalWidth, naturalHeight });
    createImageWithSpacing();
  }

  const centerCrop = () => {
    const percentCrop: PercentCrop = {
      height: 100,
      unit: '%',
      width: 100,
      x: 0,
      y: 0
    };
    setCrop(percentCrop);
    setOriginalCrop(percentCrop);
    setCompletedCrop({
      x: 0, y: 0, width: 100, height: 100, unit: '%'
    });
    setIsEmptyCrop(false);
  };

  const onCropComplete = (_pixelCrop: PixelCrop, percentCrop: PercentCrop) => {
    if (percentCrop.width > 0 && percentCrop.height > 0) {
      const calculatedCrop = calculateCrop(percentCrop);

      setOriginalCrop(percentCrop);
      setCompletedCrop(calculatedCrop);
      setIsEmptyCrop(false);
    } else {
      setIsEmptyCrop(true);
    }
  };

  const calculateCrop = (percentCrop: PercentCrop) => {
    const leftImageSide = spacing.dxPercent;
    const topImageSide = spacing.dyPercent;
    const rightImageSide = 100 - spacing.dxPercent;
    const bottomImageSide = 100 - spacing.dyPercent;

    const imageWidth = 100 - spacing.dxPercent * 2;
    const imageHeight = 100 - spacing.dyPercent * 2;
    const rightCropSide = percentCrop.x + percentCrop.width;
    const bottomCropSide = percentCrop.y + percentCrop.height;
    const leftSpace = percentCrop.x < spacing.dxPercent ? spacing.dxPercent - percentCrop.x : 0;
    const topSpace = percentCrop.y < spacing.dyPercent ? spacing.dyPercent - percentCrop.y : 0;
    const rightSpace = rightCropSide > rightImageSide ? rightCropSide - rightImageSide : 0;
    const bottomSpace = bottomCropSide > bottomImageSide ? bottomCropSide - bottomImageSide : 0;

    const isFullWidth = percentCrop.x < spacing.dxPercent && percentCrop.x + percentCrop.width > rightImageSide;
    const isFullHeight = percentCrop.y < topImageSide && percentCrop.y + percentCrop.height > bottomImageSide;

    setCompletedSpacing({
      leftSpace: percentCrop.x < spacing.dxPercent ? (spacing.dxPercent - percentCrop.x) / imageWidth * 100 : 0,
      topSpace: percentCrop.y < spacing.dyPercent ? (spacing.dyPercent - percentCrop.y) / imageHeight * 100 : 0,
      rightSpace: rightCropSide > rightImageSide ? (rightCropSide - rightImageSide) / imageWidth * 100 : 0,
      bottomSpace: bottomCropSide > bottomImageSide ? (bottomCropSide - bottomImageSide) / imageHeight * 100 : 0
    });

    return {
      x: percentCrop.x > spacing.dxPercent ? percentCrop.x - spacing.dxPercent : 0,
      y: percentCrop.y > spacing.dyPercent ? percentCrop.y - spacing.dyPercent : 0,
      width: isFullWidth ? 100 : (percentCrop.width - leftSpace - rightSpace) / imageWidth * 100,
      height: isFullHeight ? 100 : (percentCrop.height - topSpace - bottomSpace) / imageHeight * 100,
      unit: percentCrop.unit
    };
  };

  useEffect(() => {
    if (cnvRef.current) {
      createImageWithSpacing();

      cnvRef.current.removeEventListener('dblclick', centerCrop);
      cnvRef.current.addEventListener('dblclick', centerCrop);
    }
  }, [cnvRef.current]);

  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const maxModalSize = Math.min(screenWidth, screenHeight);

  const createImageWithSpacing = () => {
    const image = imgRef.current;
    const canvas = cnvRef.current;

    if (canvas) {
      const canvasWidth = maxModalSize > 600 ? 600 : maxModalSize - 64 - 48;
      const canvasHeight = maxModalSize > 600 ? 600 : maxModalSize - 64 - 48;
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const originalDiagonal = Math.sqrt(Math.pow(image.height, 2) + Math.pow(image.width, 2));

      const diagonalScale = canvasWidth / originalDiagonal;
      const width = image.width * diagonalScale;
      const height = image.height * diagonalScale;
      const context = canvas.getContext('2d');
      const dx = (canvasWidth - width) / 2;
      const dy = (canvasHeight - height) / 2;
      const dxPercent = dx / (width + dx * 2) * 100;
      const dyPercent = dy / (height + dy * 2) * 100;

      setSpacing({
        dxPercent,
        dyPercent
      });

      context.fillStyle = 'white';
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.imageSmoothingQuality = 'high';
      context.drawImage(image, dx, dy, width, height);
      setImgSpacedSrc(canvas.toDataURL('image/png'));
    }
  };

  return (
    <Modal
      open={isOpenCropDialog}
      title=""
      onDecline={() => setIsOpenCropDialog(false)}
      onAccept={() => setIsOpenCropDialog(false)}
      renderActions={() => <>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </>
      }
      paperProps={{
        sx: {
          minWidth: maxModalSize > 600 ? 600 + 'px' : 'auto',
          maxWidth: 'none',
          minHeight: maxModalSize > 600 ? 600 + 'px' : 'auto',
          maxHeight: '100%'
        }
      }}
    >
      <div style={{ display: 'flex', flex: 1 }}>
        <img ref={imgRef} src={imgSrc} onLoad={onImageLoad}
             style={{ display: 'none' }}/>
        <img ref={imgSpacedRef} src={imgSpacedSrc}
             style={{ display: 'none' }}/>
        <StyledCrop crop={crop} circularCrop aspect={1}
                    onChange={(_pixelCrop, percentCrop) => setCrop(percentCrop)}
                    onComplete={onCropComplete}
        >
          <canvas ref={cnvRef} id="output"
                  style={{
                    border: '1px dashed #cdcdcd',
                    padding: '1px'
                  }}
          />
        </StyledCrop>
      </div>
    </Modal>
  );
};
