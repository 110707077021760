import React, { FC, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_PARTICIPATION_REQUESTS } from '../../../../store/queries/requests';
import { Badge, BadgeProps, Grid, Link } from '@mui/material';
import {
  ProjectPRequest,
  ParticipationRequestStatusEnum,
  PRequestSignatureStatusFilterEnum
} from '../../../../store/generated-models';
import DataTable, { COLUMN_TYPE, DataTableColumn } from '../../components/common/DataTable/DataTable';
import { getIcon } from '../../components/common/CustomIcon';
import { useNavigate } from 'react-router-dom';
import { Panel, RequestStatusChip } from '../../components/common';
import { numberFormat } from '../../../../core/helpers/utils';
import { styled } from '@mui/material/styles';
import { Filters } from '../../components/common/DataTable/Filters';
import { Search } from '../../components/common/DataTable/Search';
import { useFilters } from '../../hooks/useFilters';
import { PageToolbar } from '../../components/common/PageToolbar/PageToolbar';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';

const moment = require('moment');

export const Requests: FC<any> = () => {
  const navigate = useNavigate();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const [requests, setRequests] = useState([]);
  const { queryVariables, onFilterChange, onSearchChange } = useFilters();

  const { data, refetch, loading } = useQuery(GET_PARTICIPATION_REQUESTS, {
    variables: {
      filterEx: queryVariables.queryFilters,
      orderBy: queryVariables.sorters
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (loading) {
    } else {
      setRequests(data?.getProjectPRequests?.list || []);
    }
  }, [data]);

  const KYBBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '&.MuiBadge-root': {
      display: 'flex'
    },
    '& .MuiBadge-badge': {
      borderRadius: '50%',
      right: '-16px',
      top: 0,
      width: '24px',
      height: '24px'
    }
  }));

  const columns: DataTableColumn[] = [
    {
      code: 'fullName',
      label: 'Investor Name',
      style: {
        paddingRight: '32px'
      },
      render: (row: any, column: any) => {
        const { isLegalPerson } = row;

        return <>
          {
            isLegalPerson ?
              <KYBBadge color="error" badgeContent={getIcon('suitcase')}>
                <span>{row['user']['fullName']}</span>
              </KYBBadge>
              :
              <span>{row['user']['fullName']}</span>
          }
        </>;
      }
    },
    {
      code: 'fundName',
      label: 'Fund Name',
      render: (row: any, column: any) => {
        return row['fundName'];
      }
    },
    {
      code: 'title',
      label: 'Project Name',
      render: (row: any, column: any) => {
        return row['project']['title'];
      }
    },
    {
      code: 'created',
      label: 'Request Date',
      type: COLUMN_TYPE.DATE,
      filter: {
        type: 'date'
      }
    },
    {
      code: 'investmentApprovedAmount',
      label: 'Investment',
      render: (row: any, column: any) => {
        return `${numberFormat(row[column.code] || row['investmentAmount'])} ${row['investmentCurrency']}`;
      },
      filter: {
        type: 'range',
        range: [0, 100000]
      },
      sorter: {
        options: [
          {
            label: 'From less to more',
            value: false
          },
          {
            label: 'From more to less',
            value: true
          }
        ]
      }
    },
    {
      code: 'status',
      label: 'Request Status',
      align: 'center',
      render: (row: any, column: any) => {
        const value = row[column.code];
        return <div style={{ display: 'flex', justifyContent: 'center' }}>
          <RequestStatusChip status={value} sx={{ width: '100px' }}/>
        </div>;
      },
      filter: {
        type: 'checkbox',
        options: [
          {
            name: ParticipationRequestStatusEnum.Approved,
            label: 'Approved',
            value: ParticipationRequestStatusEnum.Approved
          },
          {
            name: ParticipationRequestStatusEnum.Declined,
            label: 'Declined',
            value: ParticipationRequestStatusEnum.Declined
          },
          {
            name: ParticipationRequestStatusEnum.Pending,
            label: 'Pending',
            value: ParticipationRequestStatusEnum.Pending
          },
          {
            name: ParticipationRequestStatusEnum.Novation,
            label: 'Novation',
            value: ParticipationRequestStatusEnum.Novation
          }
        ]
      }
    },
    {
      code: 'saft',
      label: 'Contract',
      render: (row: any, column: any) => {
        const { contract } = row;
        const finalCopyUrl = contract ? contract.finalCopyUrl : null;

        return <>
          {contract?.created && <div style={{ display: 'flex' }}>
            {getIcon('file')}
            <Link //href={finalCopyUrl}
              underline="none"
              //onClick={(event) => event.stopPropagation()}
              target="_blank"
            >{moment(contract.created).format('DD.MM.YYYY')}</Link>
          </div>
          }
        </>;
      },
      sorter: {
        options: [
          {
            label: 'With Contract',
            value: true
          },
          {
            label: 'Without Contract',
            value: false
          }
        ]
      }
    },
    {
      code: 'novations',
      label: 'Novation',
      render: (row: any, column: any) => {
        const { novations } = row;
        const finalCopyUrl = novations?.length ? novations[0].finalCopyUrl : null;

        return <>
          {novations[0]?.created && <div style={{ display: 'flex' }}>
            {getIcon('file')}
            <Link //href={finalCopyUrl}
              underline="none"
              //onClick={(event) => event.stopPropagation()}
              target="_blank"
            >{moment(novations[0].created).format('DD.MM.YYYY')}</Link>
          </div>
          }
        </>;
      },
      sorter: {
        options: [
          {
            label: 'With novation',
            value: true
          },
          {
            label: 'Without novation',
            value: false
          }
        ]
      }
    },
    {
      code: 'signatureStatus',
      label: 'Signatures',
      render: (row: any, column: any) => {
        const { status, contract, novations } = row;
        let signedByBuyer = contract && contract.signedByBuyer;
        let signedBySeller = contract && contract.signedBySeller;

        if (novations?.length > 0 || status === ParticipationRequestStatusEnum.Novation) {
          signedByBuyer = novations[0]?.signedByBuyer;
          signedBySeller = novations[0]?.signedBySeller;
        }

        const buyerSign = signedByBuyer ? 'checkboxChecked' : 'checkboxUnchecked';
        const sellerSign = signedBySeller ? 'checkboxChecked' : 'checkboxUnchecked';

        return <div style={{ display: 'flex' }}>
          {novations?.length > 0 ?
            <>
              {getIcon(buyerSign)}
              {getIcon(sellerSign)}
            </>
            :
            <>
              {getIcon(buyerSign)}
              {getIcon(sellerSign)}
            </>
          }
        </div>;
      },
      filter: {
        type: 'checkbox',
        options: [
          {
            name: PRequestSignatureStatusFilterEnum.WaitingMe,
            label: 'Waiting for your signature',
            value: PRequestSignatureStatusFilterEnum.WaitingMe
          },
          {
            name: PRequestSignatureStatusFilterEnum.WaitingOther,
            label: 'Waiting for signature',
            value: PRequestSignatureStatusFilterEnum.WaitingOther
          },
          {
            name: PRequestSignatureStatusFilterEnum.Signed,
            label: 'Signed',
            value: PRequestSignatureStatusFilterEnum.Signed
          }
        ]
      }
    },
    {
      code: 'isLegalPerson',
      label: 'KYB',
      isHidden: true,
      sorter: {
        options: [
          {
            label: 'With KYB',
            value: true
          },
          {
            label: 'Without KYB',
            value: false
          }
        ]
      }
    }
  ];

  const rowOnClick = (row: ProjectPRequest) => {
    const id = row.projectPRequestId;

    if (id) {
      navigate(`/private/request-detailed/${id}`);
    }
  };

  useEffect(() => {
    refetch();
  }, [queryVariables]);

  const filterBlockStyles: any = {
    display: 'flex',
    justifyContent: !isMobile ? 'end' : 'normal',
    flex: 1,
    marginLeft: isPhone ? 'inherit' : 'auto',
    position: isMobile ? 'fixed' : 'inherit',
    right: isPhone ? '16px' : '24px',
    left: isPhone ? '16px' : 'auto'
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{minHeight: isPhone ? '60px' : '48px'}}>
          <PageToolbar
            leftSide={
              <div style={filterBlockStyles}>
                <Filters onChange={onFilterChange} columns={columns}/>
                <Search placeholder="Search Investor, Fund or Project" onChange={onSearchChange} fullWidth={isPhone}/>
              </div>
            }/>
        </Grid>
        <Grid item xs={12}>
          <Panel>
            <DataTable rows={requests} columns={columns} isEditOnClick rowOnClick={rowOnClick} isWithPagination
                       isLoading={loading}/>
          </Panel>
        </Grid>
      </Grid>
    </>
  );
};
