import {
  FormControl, FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select, Switch,
  TextField, ToggleButton,
  Typography
} from '@mui/material';
import React, { forwardRef, useContext, useEffect, useImperativeHandle } from 'react';
import { AuthContext } from '../../../../../core/providers/AuthProvider';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_PARTICIPATION_REQUEST, UPDATE_PARTICIPATION_REQUEST } from '../../../../../store/mutations';
import SocialList from '../../../components/common/SocialList';
import { normalizeSocials } from '../../../helpers/Normalize';
import { crudStatus } from '../../../../../core/constants/common';
import { useForm } from '../../../hooks/useForm';
import { NumberInput } from '../../../components/common/NumberInput';
import { getIcon } from '../../../components/common/CustomIcon';
import { useKYBNotification } from '../../../hooks/useKYBNotification';

export const PersonalInformation = forwardRef((props: any, ref) => {
  const { setIsStepValid, setIsLegalPerson } = props;

  useEffect(() => {
    if (setIsStepValid) {
      setIsStepValid(true);
    }
  }, []);

  const { id } = useParams();
  const [sendParticipationRequest, { loading, error }] = useMutation(CREATE_PARTICIPATION_REQUEST);
  const [updateParticipationRequest, {
    loading: updateLoading,
    error: updateError
  }] = useMutation(UPDATE_PARTICIPATION_REQUEST);
  if (loading || updateLoading) {
  }
  if (error || updateError) {
  }

  const checkEmptyValue = (value: string | number) => {
    if (typeof value === 'string') {
      return value.length > 0;
    } else {
      return typeof value !== 'undefined';
    }
  };

  const validationConfig = {
    investmentAmount: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The value cannot be empty'
      }
    },
    contribution: {
      custom: {
        isValid: checkEmptyValue,
        message: 'The value cannot be empty'
      }
    },
    communitySocials: {
      type: 'social'
    },
    userSocials: {
      type: 'social'
    }
  };

  type RequestForm = {
    fundName: string;
    investmentCurrency: string;
    investmentAmount: number | undefined;
    contribution: string;
    leadSource: string;
    communitySocials: any[];
    userSocials: any[];
    isLegalPerson: boolean;
  }

  const initialValues: RequestForm = {
    fundName: '',
    investmentCurrency: 'USDT',
    investmentAmount: undefined,
    contribution: '',
    leadSource: '',
    communitySocials: [],
    userSocials: [{
      profileUrl: '',
      profileName: '',
      social: { socialCode: 'telegram' },
      status: 0,
      isMandatory: true
    }],
    isLegalPerson: false
  };

  const onSubmit = () => true;

  const {
    handleTextChange, handleValueChange, handleSubmit, errors, data, setData, handleCheckboxChange,
    handleAddSocial, handleSocialChange, handleSocialTitleChange, handleDeleteSocial
  } = useForm<RequestForm>({ validationConfig, initialValues, onSubmit: onSubmit });

  const authContext = useContext(AuthContext);

  useEffect(() => {
    let savedRequestData: typeof data = { ...data };

    if (props.request) {
      const { request } = props;
      for (const formField in savedRequestData) {
        if (!Array.isArray(request[formField])) {
          // @ts-ignore
          savedRequestData[formField] = request[formField];
        }
      }
      savedRequestData['communitySocials'] = request['communitySocials'];
      savedRequestData['userSocials'] = request['userSocials'];

      setData(savedRequestData);
    }
  }, []);

  function handleSendParticipationRequest() {
    try {
      if (id) {
        if (props.request) {
          const participationRequest = {
            participationRequestId: props.request.projectPRequestId,
            fundName: data.fundName,
            leadSource: data.leadSource,
            contribution: data.contribution,
            investmentCurrency: data.investmentCurrency,
            investmentAmount: parseFloat(data.investmentAmount),
            isLegalPerson: data.isLegalPerson
          };
          return updateParticipationRequest({
            variables: {
              participationRequest,
              communitySocials: {
                created: normalizeSocials(data.communitySocials, crudStatus.CREATED),
                updated: normalizeSocials(data.communitySocials, crudStatus.UPDATED),
                deleted: normalizeSocials(data.communitySocials, crudStatus.DELETED)
              },
              userSocials: {
                created: normalizeSocials(data.userSocials, crudStatus.CREATED),
                updated: normalizeSocials(data.userSocials, crudStatus.UPDATED),
                deleted: normalizeSocials(data.userSocials, crudStatus.DELETED)
              }
            }
          });
        } else {
          const participationRequest = {
            userId: authContext.user.userId,
            fundName: data.fundName,
            leadSource: data.leadSource,
            contribution: data.contribution,
            isLegalPerson: data.isLegalPerson,
            investmentCurrency: data.investmentCurrency,
            investmentAmount: parseFloat(data.investmentAmount),
            projectId: id
          };
          return sendParticipationRequest({
            variables: {
              participationRequest,
              communitySocials: {
                created: normalizeSocials(data.communitySocials, crudStatus.CREATED)
              },
              userSocials: {
                created: normalizeSocials(data.userSocials, crudStatus.CREATED)
              }
            }
          });
        }
      }
    } catch (e) {
    }
  }

  useKYBNotification(data.isLegalPerson);

  useImperativeHandle(ref, () => ({
    handleSendParticipationRequest() {
      const isReadyForSubmit = handleSubmit();
      if (isReadyForSubmit) {
        return handleSendParticipationRequest();
      } else {
        return Promise.reject();
      }
    }
  }));

  return (
    <Grid container spacing={7}>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <TextField label="Which fund do you represent?" name="fundName" onChange={handleTextChange}
                       variant="outlined"
                       fullWidth value={data.fundName}/>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px' }}>
              Please provide the links to your social media
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <SocialList data={data.userSocials}
                        handleSocialChange={handleSocialChange('userSocials')}
                        errors={errors.userSocials}
                        handleAddSocial={handleAddSocial('userSocials')}
                        addButtonLabel={'Add personal social media'}
                        handleSocialTitleChange={handleSocialTitleChange('userSocials')}
                        handleDeleteSocial={handleDeleteSocial('userSocials')}
                        checkIsMandatory={(item: any) => item.social.socialCode === 'telegram'}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px' }}>
              How much in USDT or USDC would you like to invest in our private sale?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id={`Сurrency`}>Сurrency</InputLabel>
              <Select fullWidth
                      name="investmentCurrency"
                      label="Сurrency"
                      labelId={`Сurrency`}
                      value={data.investmentCurrency}
                      onChange={handleTextChange}
              >
                <MenuItem value="USDT">USDT</MenuItem>
                <MenuItem value="USDC">USDC</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput label="Quantity" name="investmentAmount"
                         onChange={(event: any, value: number) => handleValueChange(event, value)}
                         variant="outlined"
                         error={errors.investmentAmount && errors.investmentAmount.length > 0}
                         fullWidth value={data.investmentAmount || 0}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '8px', fontSize: '16px' }}>Apply as legal person (KYB verification
              required)</Typography>
            <FormControlLabel
              control={
                <Switch name="isLegalPerson" onChange={(event) => {
                  handleCheckboxChange(event);

                  if (setIsLegalPerson) {
                    setIsLegalPerson(event.target.checked);
                  }
                }} checked={!!data.isLegalPerson}/>
              }
              label="Yes"
            />
          </Grid>

        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={4}>
          <Grid item sm={12}>
            <Typography sx={{ fontSize: '16px' }}>
              Which communities or influencers would you be able to introduce?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SocialList data={data.communitySocials}
                        errors={errors.communitySocials}
                        handleAddSocial={handleAddSocial('communitySocials')}
                        addButtonLabel={'Add community or influencer'}
                        handleSocialChange={handleSocialChange('communitySocials')}
                        handleSocialTitleChange={handleSocialTitleChange('communitySocials')}
                        handleDeleteSocial={handleDeleteSocial('communitySocials')}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', marginBottom: '8px' }}>
              Please share with us any other strategic value you can contribute to the project.
            </Typography>
            <TextField name="contribution" onChange={handleTextChange} variant="outlined" multiline rows={5}
                       error={errors.contribution && errors.contribution.length > 0}
                       fullWidth value={data.contribution}/>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: '16px', marginBottom: '8px' }}>
              How did you find out about us?
            </Typography>
            <TextField name="leadSource" onChange={handleTextChange} variant="outlined" multiline rows={3}
                       fullWidth value={data.leadSource}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});
