import React from 'react';
import { DateFilter } from './DateFilter';
import { CheckboxFilter } from './CheckboxFilter';
import { RangeFilter } from './RangeFilter';
import { FilterTypeEnum } from '../../../../../store/generated-models';

export const FilterItem = (props: any) => {
  const { filter, title, value, onChange } = props;

  return <div className="filter">
    <div className="filter__title">
      {title}
    </div>
    <div className="filter__control">
      {filter.type === 'date' && <DateFilter config={filter} value={value} onChange={(value: any) => onChange(FilterTypeEnum.Date, value)}/>}
      {filter.type === 'checkbox' && <CheckboxFilter config={filter} value={value} onChange={(value: any) => onChange(FilterTypeEnum.Date, value)}/>}
      {filter.type === 'range' && <RangeFilter config={filter} value={value} onChange={(value: any) => onChange(FilterTypeEnum.Number, value)}/>}
    </div>
  </div>;
};
