import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography, InputAdornment, InputLabel, Select, MenuItem, FormControl, Collapse, IconButton
} from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { DatePicker } from '@mui/lab';
import { getIcon } from '../../components/common/CustomIcon';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_HS_TEMPLATES } from '../../../../store/queries/helloSign';
import {
  HsTemplateType, RollingFundApprovalStatusEnum, RollingFundInput, RollingFundPeriodTypeEnum, RollingFundStatusEnum
} from '../../../../store/generated-models';
import { useForm } from '../../hooks/useForm';
import { Modal, Panel } from '../../components/common';
import { useSnackbar } from 'notistack';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';
import { NumberInput } from '../../components/common/NumberInput';
import { ExpandMore } from '../../components/common/ExpandMore/ExpandMore';
import { RichText } from '../../components/common/RichText';
import { CropUploadField } from '../../components/common/CropField/CropUploadField';
import { CREATE_FUND, DELETE_FUND, UPDATE_FUND } from '../../../../store/mutations/funds';
import { GET_FUND_BY_ID } from '../../../../store/queries/funds';
import ClearIcon from '@mui/icons-material/Clear';
import { MinPeriodInput } from './MinPeriodInput';
import { checkNonZeroValue } from '../../../../core/helpers/utils';
import './FundDetailedView.scss';
import {AuthContext} from "../../../../core/providers/AuthProvider";

export const FundDetailedView: FC<any> = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { isPhone, isTablet, isMobile } = useContext(ScreenContext);

  const validationConfig = {
    title: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'The name cannot be empty'
      }
    },
    minAmount: {
      custom: {
        isValid: checkNonZeroValue,
        message: 'The value must be greater than 0'
      }
    },
    managementFee: {
      custom: {
        isValid: checkNonZeroValue,
        message: 'The value must be greater than 0'
      }
    },
    minPeriod: {
      custom: {
        isValid: checkNonZeroValue,
        message: 'The value must be greater than 0'
      }
    },
    startDate: {
      custom: {
        isValid: (value: string) => {
          return value !== null && value !== 'undefined';
        },
        message: 'The date cannot be empty'
      }
    },
    portfolio: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'Portfolio cannot be empty'
      }
    },
    terms: {
      custom: {
        isValid: (value: string) => {
          return value.length > 0;
        },
        message: 'Terms cannot be empty'
      }
    }
  };

  type FundForm = {
    title: string;
    introductionLink: string;
    description: string;
    externalTemplateId: string;
    contributionWallet: string;
    status: boolean;
    approvalStatus: boolean;
    minAmount: number;
    managementFee: number;
    minPeriod: number;
    minPeriodType: RollingFundPeriodTypeEnum;
    startDate: Date | null;
    portfolio: any[];
    terms: string;
  }

  const initialValues: any = {
    title: '',
    introductionLink: '',
    description: '',
    externalTemplateId: '',
    contributionWallet: '',
    status: true,
    approvalStatus: true,
    minAmount: 0,
    managementFee: 0,
    minPeriod: 0,
    minPeriodType: RollingFundPeriodTypeEnum.Month,
    startDate: null,
    portfolio: [],
    terms: ''
  };

  const {
    handleValueChange,
    handleTextChange,
    handleRichEditorChange,
    handleSubmit,
    errors,
    data,
    setData,
    handleCheckboxChange,
    handleDateChange: _handleDateChange,

    handleAddListItem,
    handleChangeListItem,
    handleDeleteListItem
  } = useForm<FundForm>({ validationConfig, initialValues, onSubmit });

  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [formLoaded, setFormLoaded] = useState(!id);

  const [withdrawExpanded, setWithdrawExpanded] = useState(false);
  const [withdrawApproval, setWithdrawApproval] = useState(false);
  const [novationExpanded, setNovationExpanded] = useState(false);
  const [moveToNovation, setMoveToNovation] = useState(false);

  const approvedRequestCount = 3;
  const countersignedCount = 2;
  const [isOpenTokenomicsDialog, setIsOpenTokenomicsDialog] = useState(false);
  const tokenomicsModal = () => <>
    <Modal
      open={isOpenTokenomicsDialog}
      title="Tokenomics Change"
      renderActions={() => <>
        <Button onClick={() => setIsOpenTokenomicsDialog(false)}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </>}
    >
      <>
        <Typography sx={{ marginBottom: '16px' }}>
          {
            `You are about to change the Tokenomics on this project. 
          You have ${approvedRequestCount} already approved requests and ${countersignedCount} countersigned contracts.`
          }
        </Typography>
        <div style={{ display: 'flex', flexFlow: 'column' }}>
          <div style={{ display: 'flex' }}>
            <Switch name="withdrawApproval" checked={withdrawApproval}
                    onChange={(event) => setWithdrawApproval(event.target.checked)}
            />
            <div>
              <Typography>
                Withdraw approval from all already approved requests
              </Typography>
              <ExpandMore
                expand={withdrawExpanded}
                onClick={() => setWithdrawExpanded(!withdrawExpanded)}
                aria-expanded={withdrawExpanded}
                aria-label="Learn more"
              />
              <Collapse in={withdrawExpanded} timeout="auto" unmountOnExit>
                <ul style={{ padding: '0 24px' }}>
                  <li>
                    If the operation is activated: all request approvals (not countersigned) for this project will be
                    withdrawn. Then, you can manually decline / change the allocation and again approve requests for
                    each
                    individual person. Note that you won’t be able to agree on old terms with the investors.
                  </li>
                  <li>
                    If the operation is not activated: all request approvals will not be withdrawn, meaning you will be
                    able
                    to continue with contract signature on old terms (before the tokenomics change).
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <Switch name="moveToNovation" checked={moveToNovation}
                    onChange={(event) => setMoveToNovation(event.target.checked)}
            />
            <div>
              <Typography>
                Move all countersigned contracts to Novation
              </Typography>
              <ExpandMore
                expand={novationExpanded}
                onClick={() => setNovationExpanded(!novationExpanded)}
                aria-expanded={novationExpanded}
                aria-label="Learn more"
              />
              <Collapse in={novationExpanded} timeout="auto" unmountOnExit>
                <ul style={{ padding: '0 24px' }}>
                  <li>
                    If the operation is activated: all requests with countersigned contracts will be moved to Novation.
                    You
                    will
                    see the new Novation status for such requests. Please note that you will need to approve the new
                    token
                    allocation for contract's under Novation.
                  </li>
                  <li>
                    If the operation is not activated: all requests with countersigned contracts will be in approved
                    status,
                    Novation will not be triggered. However, you can manually move the request to Novation on the
                    request
                    details page.
                  </li>
                </ul>
              </Collapse>
            </div>
          </div>
        </div>
      </>
    </Modal>
  </>;

  const handleSave = () => {
    const isWithConfirm = false;

    if (isWithConfirm) {
      setIsOpenTokenomicsDialog(true);
    } else {
      handleSubmit();
    }
  };

  const { data: fundData, loading } = useQuery(GET_FUND_BY_ID, {
    variables: {
      rollingFundId: id
    },
    fetchPolicy: 'network-only',
    skip: !id
  });

  const { data: helloSignData, loading: helloSignLoading } = useQuery(GET_HS_TEMPLATES, {
    variables: { templateType: HsTemplateType.RollingFundPRequestContract },
    fetchPolicy: 'network-only'
  });

  const [helloSignTemplates, setHelloSignTemplates] = useState([]);
  useEffect(() => {
    if (helloSignData) {
      setHelloSignTemplates(helloSignData.getHsTemplates);
    }
  }, [helloSignData]);

  const [imgSrc, setImgSrc] = useState('');
  useEffect(() => {
    let savedFundData: typeof data = { ...data };

    if (fundData) {
      let fund = fundData.getRollingFundById;

      for (const formField in savedFundData) {
        // @ts-ignore
        savedFundData[formField] = fund[formField];
      }
      savedFundData['status'] = fund.status === RollingFundStatusEnum.Active;
      savedFundData['approvalStatus'] = fund.approvalStatus === RollingFundApprovalStatusEnum.Approved;
      // savedFundData['portfolio'] = savedFundData.portfolio ? savedFundData.portfolio.split(',').map((title: string) => ({
      //   title: title.trim()
      // })) : [];
      setData(savedFundData);
      setFormLoaded(true);
      setImgSrc(fund.avatar ? `${process.env.REACT_APP_API_URL_REST}${fund.avatar}` : '');
    }
  }, [fundData]);

  const [createFund, {
    data: dataCreate,
    loading: loadingProjectCreate,
    error: errorProjectCreate
  }] = useMutation(CREATE_FUND);

  const [updateFund, {
    data: dataUpdate,
    loading: loadingProjectUpdate,
    error: errorProjectUpdate
  }] = useMutation(UPDATE_FUND);

  const handleCancel = () => {
    navigate('/private/funds');
  };

  const handleDateChange = _handleDateChange('startDate');
  const onClearDate = () => {
    handleDateChange(null);
  };

  const [avatar, setAvatar] = useState({
    files: [],
    completedCrop: {},
    completedSpacing: {}
  });
  const onCropUpload = (value: any) => {
    setAvatar(value);
  };

  function onSubmit() {
    try {
      const rollingFund: RollingFundInput = {
        title: data.title,
        description: data.description,
        introductionLink: data.introductionLink,
        contributionWallet: data.contributionWallet,
        externalTemplateId: data.externalTemplateId,
        status: data.status ? RollingFundStatusEnum.Active : RollingFundStatusEnum.Inactive,
        approvalStatus: data.approvalStatus
          ? RollingFundApprovalStatusEnum.Approved
          : RollingFundApprovalStatusEnum.Declined,
        minAmount: data.minAmount,
        managementFee: data.managementFee,
        minPeriod: data.minPeriod,
        minPeriodType: data.minPeriodType,
        startDate: data.startDate,
        terms: data.terms,
        portfolio: data.portfolio
      };

      if (id) {
        updateFund({
          variables: {
            rollingFund: {
              ...rollingFund,
              rollingFundId: id
            },
            avatar: avatar.files[0],
            crop: avatar.completedCrop,
            spacing: avatar.completedSpacing
          }
        }).then(() => {
          navigate('/private/funds');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      } else {
        createFund({
          variables: {
            rollingFund,
            avatar: avatar.files[0],
            crop: avatar.completedCrop,
            spacing: avatar.completedSpacing
          }
        }).then(() => {
          navigate('/private/funds');
        }).catch((error: any) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        });
      }
    } catch (e) {
    }
  }

  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);


  function confirmDelete() {
    setIsOpenDeleteDialog(true);
  }

  const [deleteFund, {
    data: dataDelete,
    loading: loadingProjectDelete,
    error: errorProjectDelete
  }] = useMutation(DELETE_FUND);
  const handleDelete = () => {
    deleteFund({
      variables: {
        rollingFundId: id
      }
    }).then(() => {
      navigate('/private/funds');
    }).catch((error: any) => {
      enqueueSnackbar('Internal server error', { ...{ variant: 'error' } });
    });
  };

  let defaultHelloSignTemplate = helloSignTemplates.length > 0 ? helloSignTemplates[0].templateId : '';

  useEffect(() => {
    if (defaultHelloSignTemplate) {
      setData({
        ...data,
        externalTemplateId: defaultHelloSignTemplate
      });
    }
  }, [defaultHelloSignTemplate]);

  return (
    <Panel>
      {tokenomicsModal()}
      <Modal
        open={isOpenDeleteDialog}
        title="Delete Fund?"
        renderActions={() => <>
          <Button onClick={() => setIsOpenDeleteDialog(false)} disabled={loadingProjectDelete}>No</Button>
          <Button onClick={handleDelete} disabled={loadingProjectDelete}>Yes</Button>
        </>}
      >
        <>
          <Typography sx={{ marginBottom: '16px' }}>
            {`Are you sure you want to delete the fund "${data.title}"?`}
          </Typography>
        </>
      </Modal>

      <div className="form">
        <div className="form__body">
          <Grid container spacing={{ md: 7, xs: 2 }}>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={{ sm: 4, xs: 2 }}>
                <Grid item sm={5} xs={12}>
                  <CropUploadField defaultImgSrc={imgSrc} onChange={onCropUpload}/>
                </Grid>
                <Grid item sm={7} xs={12}>
                  <Grid container columnSpacing={2} rowSpacing={{ md: 4, xs: 2 }}>
                    <Grid item xs={12}>
                      <TextField label="Fund Name" name="title" required variant="outlined" fullWidth
                                 onChange={handleTextChange}
                                 error={errors.title && errors.title.length > 0}
                                 value={data.title}/>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField label="Link to the Intro Materials" name="introductionLink" variant="outlined"
                                 fullWidth onChange={handleTextChange} value={data.introductionLink}/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Contribution Wallet" name="contributionWallet" variant="outlined"
                             fullWidth onChange={handleTextChange} value={data.contributionWallet}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField label="Fund Description" name="description" variant="outlined" multiline rows={5}
                             fullWidth
                             onChange={handleTextChange} value={data.description}/>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id={'externalTemplateId'}>Hellosign Template ID</InputLabel>
                    {
                      <Select fullWidth
                              label="Hellosign Template ID"
                              labelId="externalTemplateId"
                              name="externalTemplateId"
                              value={data.externalTemplateId || defaultHelloSignTemplate}
                              onChange={handleTextChange}
                      >
                        {
                          helloSignTemplates.length > 0 && helloSignTemplates.map((template: any, index: number) => (
                            <MenuItem key={index} value={template.templateId}>
                              <Typography>{template.title}</Typography>
                            </MenuItem>)
                          )
                        }
                      </Select>
                    }
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Switch name="status" onChange={handleCheckboxChange}
                              checked={!!data.status}/>
                    }
                    label="Active"
                  />
                </Grid>
                { authContext.isAdmin &&
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6" style={{ marginBottom: '24px' }}>Approval</Typography>
                      <Typography style={{ fontSize: '12px', lineHeight: '18px', color: '#BBBDC0' }}>
                        Here you can set the approval status of the fund. Funds without approval will not be listed on the platform, but can still be seen and managed by the responsible fund owners.
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <FormControlLabel
                        control={
                          <Switch name="approvalStatus" onChange={handleCheckboxChange}
                                  checked={!!data.approvalStatus}/>
                        }
                        label="Approved"
                      />
                    </Grid>
                  </>
                }
                <Grid item xs={12}>
                  <Typography variant="h6">Subscription Details</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={{ sm: 4, xs: 2 }}>
                    <Grid item xs={12} sm={6}>
                      <NumberInput name="minAmount" label="Minimum Amount" variant="outlined" fullWidth
                                   required error={errors.minAmount && errors.minAmount.length > 0}
                                   InputProps={{
                                     endAdornment: <InputAdornment
                                       position="end">{getIcon('dollar')}</InputAdornment>
                                   }}
                                   onChange={handleValueChange}
                                   value={data.minAmount || 0}
                                   precision={0}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <NumberInput name="managementFee" label="Management Fee" variant="outlined" fullWidth
                                   required error={errors.managementFee && errors.managementFee.length > 0}
                                   InputProps={{
                                     endAdornment: <InputAdornment
                                       position="end">% PER ANNUM</InputAdornment>
                                   }}
                                   onChange={handleValueChange}
                                   value={data.managementFee || 0}
                                   precision={2}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MinPeriodInput errors={errors} value={data.minPeriod} typeValue={data.minPeriodType}
                                      onValueChange={handleValueChange} onTypeChange={handleTextChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container>
                        <Grid item xs={9}>
                          {
                            formLoaded && <DatePicker
                              label="Start Date"
                              value={data.startDate}
                              onChange={handleDateChange}
                              renderInput={(params) => <TextField required
                                                                  fullWidth={true} {...params}
                                                                  error={errors.startDate && errors.startDate.length > 0}
                              />}
                              PopperProps={{
                                placement: 'bottom-start',
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, 8]
                                    }
                                  }
                                ]
                              }}
                              inputFormat="dd.MM.yyyy"
                              mask="__.__.____"
                            />
                          }
                        </Grid>
                        <Grid item xs={3} sx={{ display: 'flex' }}>
                          <div className="icon-button-wrapper">
                            <IconButton onClick={() => {
                              onClearDate();
                            }}>
                              <ClearIcon/>
                            </IconButton>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container spacing={{ md: 4, xs: 2 }}>
                {/*<Grid item xs={12}>
                  <Typography variant="h6">Portfolio</Typography>
                </Grid>
                <Grid item xs={12}>
                  <PortfolioList
                    data={data.portfolio}
                    errors={errors.portfolio}
                    handleAdd={handleAddListItem('portfolio', { title: '' })}
                    handleTitleChange={handleChangeListItem}
                    handleDelete={handleDeleteListItem('portfolio', 'portfolioId')}
                  />
                </Grid>*/}
                <Grid item xs={12}>
                  <TextField label="Portfolio" name="portfolio" required variant="outlined" fullWidth
                             onChange={handleTextChange}
                             error={errors.portfolio && errors.portfolio.length > 0}
                             value={data.portfolio}/>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" style={{ marginBottom: '24px' }}>Agree Terms</Typography>
                  <Typography style={{ fontSize: '12px', lineHeight: '18px', color: '#BBBDC0' }}>
                    Please specify the agreement terms relevant for your fund.
                    The investor will be required to accept these terms to proceed with the participation.
                    You can change the agreement terms at any time.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RichText onChange={(value) => handleRichEditorChange('terms', value)} text={data.terms}
                            error={errors.terms && errors.terms.length > 0}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="form__buttons">
          <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    onClick={handleCancel}>Cancel</Button>
            {!isPhone && id && <Button sx={{ width: { xs: '96px', md: 'auto' } }} variant="outlined"
                                       onClick={confirmDelete} color="error"
                                       disabled={loadingProjectDelete}
            >Delete</Button>
            }
          </div>
          {isPhone && id && <div className="button-group">
            <Button variant="outlined" sx={{ width: { xs: '96px', md: 'auto' } }} onClick={confirmDelete}
                    color="error">Delete</Button>
          </div>}
          <div className="button-group">
            <Button variant="contained" sx={{ width: { xs: id ? '96px' : '140px', md: 'auto' } }}
                    disabled={(loadingProjectCreate && !errorProjectCreate) || (loadingProjectUpdate && !errorProjectUpdate)}
                    onClick={handleSave}>Save</Button>
          </div>
        </div>
      </div>
    </Panel>
  );
};
