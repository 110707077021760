import { StepProps } from '../FundParticipationRequest';
import {
  Button,
  Box,
  Typography
} from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import config from "../../../../../../config";

export const Success: StepProps = forwardRef((props: any, ref) => {
  const { isLegalPerson, createdItemId } = props;
  const navigate = useNavigate();

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '400px',
      marginBottom: '60px',
      textAlign: 'center'
    }}>
      <Typography variant="subtitle1"
                  sx={{
                    fontSize: '20px',
                    marginBottom: '16px'
                  }}
      >
        {
          isLegalPerson ? 'Complete KYB verification' : 'Thank you for your request!'
        }
      </Typography>
      {
        isLegalPerson ?
          <>
            <Typography variant="body1" sx={{ color: '#7A858F' }}>
              Please proceed with the KYB verification by
            </Typography>
            <Typography variant="body1" sx={{ color: '#7A858F', marginBottom: '32px' }}>
              clicking on the button below
            </Typography>
          </>
          :
          <>
            <Typography variant="body1" sx={{ maxWidth: '370px', color: '#7A858F', marginBottom: '32px' }}>
              In the near future we will process your application
              and let you know about our decision.
            </Typography>
          </>
      }

      {
        isLegalPerson ?
          <Button variant="contained" endIcon={<ArrowForward/>}
                  onClick={() => window.open(config.kybUrl, '_blank')}
          >
            Proceed with KYB
          </Button>
          :
          <Button variant="contained" onClick={() => navigate(`/private/fund-request-detailed/${createdItemId}`)}>
            See Request
          </Button>
      }
    </Box>
  );
});
