import { Button, TextField, Typography } from '@mui/material';
import { Modal } from '../../../../../components/common';
import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  WITHDRAW_ROLLING_FUND_APPROVAL_PARTICIPATION_REQUEST
} from '../../../../../../../store/mutations/participationRequest';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

export const useWithdrawApprovalModal = (request: any, refetch: any) => {
  const [isOpenWithdrawApproval, setIsOpenWithdrawApproval] = useState(false);
  const [withdrawComment, setWithdrawComment] = useState('');
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [withdrawApprovalParticipationRequest, { loading }] = useMutation(WITHDRAW_ROLLING_FUND_APPROVAL_PARTICIPATION_REQUEST);
  const handleAcceptWithdrawApproval = useCallback(async () => {
    await withdrawApprovalParticipationRequest({
      variables: {
        participationRequestId: request.rollingFundPRequestId,
        comment: withdrawComment
      }
    }).then(() => {
      setIsOpenWithdrawApproval(false);
      refetch();
    }).catch((error: any) => {
      enqueueSnackbar(error.message, { ...{ variant: 'error' } });
    });
  }, [withdrawComment]);

  const handleWithdrawComment = (event: any) => {
    setWithdrawComment(event.target.value);
  };


  const modal = <Modal open={isOpenWithdrawApproval} title="Withdraw Approval"
                       renderActions={() => <>
                         <Button onClick={() => setIsOpenWithdrawApproval(false)} disabled={loading}>No</Button>
                         <Button onClick={handleAcceptWithdrawApproval} disabled={loading}>Yes</Button>
                       </>}
  >
    <>
      <Typography sx={{ marginBottom: '16px' }}>
        Please leave a comment on why you withdraw the approval
      </Typography>
      <TextField label="Comment" name="withdrawComment" variant="outlined" multiline rows={5}
                 fullWidth
                 onChange={handleWithdrawComment} value={withdrawComment}/>
    </>
  </Modal>;

  return {
    modal,
    setIsOpenWithdrawApproval
  };
};
