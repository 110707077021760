import React, {useContext, useState} from 'react';
import {LoginResult, MutationVerify2faCodeArgs} from '../../../store/generated-models';
import {AuthContext} from '../../../core/providers/AuthProvider';
import TextField from  '@mui/material/TextField';
import {gqlVerify2faCode} from '../../../core/providers/gql';
import {useMutation} from '@apollo/client';
import { Button, Typography, Grid, Box } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";

export const Confirm2fa: React.FC<any> = (props) => {
  const navigate = useNavigate();
  const [twoFa, setTwoFa] = useState('');

  const authContext = useContext(AuthContext);

  const [verify2faCode] = useMutation<{ verify2faCode: LoginResult }, MutationVerify2faCodeArgs>(gqlVerify2faCode);

  const onFormSubmit = (e: any) => {
    e.preventDefault();

    authContext.updateRecaptcha()
      .then((recaptchaToken) => {
        verify2faCode({
          variables: {
            code: twoFa
            // token: props.token
            // recaptcha: recaptchaToken
          }
        })
          .then((res: any) => {
            const loginData = res.data.verify2faCode;
            authContext.login(loginData);
          })
          .catch((error: any) => {
            try {
              const errorCode = error.graphQLErrors[0].extensions.code;
              if (errorCode === 'auth.token_verification') {
                authContext.showMessage('error', 'Verification error occurred');
              } else {
                authContext.showMessage('error', 'Unknown error occurred');
              }
            } catch {
              authContext.showMessage('error', 'Unknown error occurred');
            }
          });
      })
      .catch(() => {
        console.warn('gr promise error');
      });
  };

  return (
    <>
      <Typography component='span' sx={{fontWeight: 600, fontSize: '20px', marginBottom: '8px'}}>
        2 Factor Authentication
      </Typography>
      <Typography component='span' sx={{fontSize: '14px', marginBottom: '40px', color: '#BBBDC0'}}>
        Please insert the code from the Authenticator below
      </Typography>

      <form onSubmit={onFormSubmit} autoComplete="off">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              style={{margin: '12px 0 24px'}}
              variant="outlined"
              fullWidth
              label="Enter 2FA Code"
              autoComplete="off"
              value={twoFa}
              onChange={e => setTwoFa(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size={'large'}
            >
              Continue
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{display: 'flex'}}>
              <Button startIcon={<ArrowBackIcon />} sx={{margin: '0 auto', fontSize: '14px', fontWeight: 500}}
                      onClick={() => window.location.reload()}>
                Back to Log In
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
