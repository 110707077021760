import React, {PropsWithChildren, useContext, useEffect} from 'react';
import {NavigationContext} from '../../../../../core/components/BrowserRouter';
import {styled} from '@mui/material/styles';
import {Box} from '@mui/material';

interface InternalPageProps {
  title: string;
}

const StyledBox = styled(Box)(({theme}) => ({
  [theme.breakpoints.up('sm')]: {
    padding: '24px 24px'
  },
  [theme.breakpoints.down('sm')]: {
    padding: '16px 16px'
  },
  flexGrow: 1
}));

export const InternalPage = (props: PropsWithChildren<InternalPageProps>) => {
  const {children, title} = props;
  const navigationContext = useContext(NavigationContext);

  useEffect(() => {
    navigationContext.setPageName(title);
  }, [title]);

  return <StyledBox>
    {children}
  </StyledBox>;
};
