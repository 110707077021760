import React, { FC, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { getIcon } from '../../components/common/CustomIcon';
import { PrivateSales } from '../../user/privateSales/PrivateSales';
import { Projects } from './Projects';
import { styled } from '@mui/material/styles';

export const ProjectsSwitch: FC<any> = () => {
  const [mode, setMode] = useState('table');

  const handleModeSwitch = (event: React.MouseEvent<HTMLElement>, newMode: string) => {
    if (newMode !== null) {
      setMode(newMode);
    }
  };

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
      marginLeft: '8px',
      padding: '8px',
      border: 0,
      backgroundColor: 'white',
      '&.Mui-selected': {
        backgroundColor: '#4A4DF0'
      }
    }
  }));

  const modeSwitcher = <StyledToggleButtonGroup
    value={mode} exclusive
    onChange={handleModeSwitch}
  >
    <ToggleButton value="table" color="primary">
      {getIcon('list', mode === 'table' ? 'stroke-white' : 'stroke-black')}
    </ToggleButton>
    <ToggleButton value="cards" color="primary">
      {getIcon('cards', mode === 'cards' ? 'stroke-white' : 'stroke-black')}
    </ToggleButton>
  </StyledToggleButtonGroup>;

  return (
    <>
      {
        mode === 'table' && <Projects toolbarItem={modeSwitcher}/>
      }
      {
        mode === 'cards' && <PrivateSales toolbarItem={modeSwitcher}/>
      }
    </>
  );
};
