import React from 'react';
import { routes } from './routes';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { AuthProvider } from './core/providers/AuthProvider';
import { RestApiProvider } from './core/providers/RestApiProvider';
import { AppThemeProvider } from './core/providers/ThemeProvider';
import { apolloClient } from './store/apolloClient';
import { ApolloProvider } from '@apollo/client';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { BrowserRouter, history } from 'core/components/BrowserRouter';
import { Routes } from 'react-router-dom';
import './App.scss';
import { ScreenProvider } from './core/providers/ScreenProvider';

const App: React.FC = () => {
  return (
    <>
      <CssBaseline/>
      <ApolloProvider client={apolloClient}>
        <BrowserRouter history={history}>
          <AppThemeProvider>
            <AuthProvider>
              <RestApiProvider>
                <ScreenProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider
                      maxSnack={3}
                      autoHideDuration={3000}
                      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                    >
                      <Routes>
                        {routes}
                      </Routes>
                    </SnackbarProvider>
                  </LocalizationProvider>
                </ScreenProvider>
              </RestApiProvider>
            </AuthProvider>
          </AppThemeProvider>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
};

export default App;
