import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import { TwoFactorAuthenticationResult } from '../../../../store/generated-models';
import { AuthContext } from '../../../../core/providers/AuthProvider';
import { Step2faEnable } from './Step2faEnable';
import { Step2faDisable } from './Step2faDisable';
import { gql, useMutation } from '@apollo/client';
import { Panel } from '../../components/common';
import { ScreenContext } from '../../../../core/providers/ScreenProvider';

const Row = styled.div`
  display: flex;
  justify-content: center;
`;

const Alert = styled.span`
  color: ${props => props.theme.custom.palette.alert};
  font-weight: bold;
`;

const Success = styled.span`
  color: ${props => props.theme.custom.palette.success};
  font-weight: bold;
`;

const gqlGenerate2faCode = gql`
  mutation Generate2faCode {
    generate2faCode {
      code
      qr
    }
  }
`;

export interface ComponentProps {
}

export const Security: React.FC<ComponentProps> = () => {

  const authContext = useContext(AuthContext);
  const [generate2faCode] = useMutation<{ generate2faCode: TwoFactorAuthenticationResult }>(gqlGenerate2faCode);

  const [status, setStatus] = useState('disabled');
  const [qrCode, setQrCode] = useState('');
  const [qrString, setQrString] = useState('');

  const { isPhone } = useContext(ScreenContext);

  useEffect(() => {
    setStatus(authContext.user.is2faEnabled ? 'enabled' : 'disabled');
  }, [authContext.user.is2faEnabled]);

  const changeStatusClickHandler = async () => {
    if (status === 'disabled') {
      try {
        const res = await generate2faCode({});
        if (res && res.data) {
          setQrCode(res.data.generate2faCode.code);
          setQrString(res.data.generate2faCode.qr);
          setStatus('step-enable');
        }
      } catch (error) {
        console.warn('getOAuthParams error:', error);
      }
    } else {
      setStatus('step-disable');
    }
  };

  return (
    <div style={{ display: isPhone ? 'block' : 'flex' }}>
      <Panel>
        {(status === 'step-enable') && <Step2faEnable
          qrCode={qrCode}
          qrString={qrString}
          onCancel={() => setStatus('disabled')}
        />}

        {(status === 'step-disable') && <Step2faDisable
          onCancel={() => setStatus('enabled')}
        />}

        {(status === 'disabled' || status === 'enabled') && <>
          <Row style={{ marginTop: '4px' }}>
            <Typography variant={'body1'}>
              Two-factor authentication is&nbsp;
              {status === 'disabled' ? <Alert>DISABLED</Alert> : <Success>ENABLED</Success>}
            </Typography>
          </Row>

          <Row style={{ marginTop: '24px' }}>
            <Button
              variant="contained"
              color="primary"
              size={'large'}
              style={{ minWidth: '240px' }}
              onClick={changeStatusClickHandler}
            >
              {status === 'disabled' ? 'Enable 2FA' : 'Disable 2FA'}
            </Button>
          </Row>
        </>
        }
      </Panel>
    </div>
  );
};
