import React, {useState} from 'react';
import MuiFormControl from  '@mui/material/FormControl';
import InputLabel from  '@mui/material/InputLabel';
import styled, {css} from 'styled-components';
import OutlinedInput from  '@mui/material/OutlinedInput';
import IconButton from  '@mui/material/IconButton';
import InputAdornment from  '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const FormControl = styled(MuiFormControl)<{ mb: number }>`
  ${props => props.mb && css`
      margin-bottom: ${props.theme.spacing(props.mb)}px;
`}`;

interface ComponentProps {
  id?: string;
  label?: any;
  value?: string;
  name?: string;
  helperText?: string;
  onChange?: (e: any) => void;
  size?: 'small' | 'medium',
  fullWidth?: boolean,
  mb?: number,
  disabled?: boolean,
  readOnly?: boolean,
  autoComplete?: string,
  style?: any,
  error?: any
}

export const Password: React.FC<ComponentProps> = (props) => {

  // const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(oldVal => !oldVal);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <FormControl
      size={props.size}
      fullWidth={props.fullWidth}
      mb={props.mb}
      style={props.style}
    >
      <InputLabel
        htmlFor={props.id}
        className={'MuiInputLabel-outlined'}
        style={{background: '#FFF', paddingRight: '8px', paddingLeft: '8px'}}
      >
        {props.label}
      </InputLabel>
      <OutlinedInput
        id={props.id}
        name={props.name}
        type={showPassword ? 'text' : 'password'}
        value={props.value}
        onChange={props.onChange}
        error={props.error}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility/> : <VisibilityOff/>}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );

};
