import React from 'react';
import {
  RollingFundPRequest,
  PRequestProgressStep,
  PRequestStepCodeEnum, PRequestStepCodeLegalEnum
} from '../../../../../../../store/generated-models';
import { Button, Grid, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import './RequestProgress.scss';
import { Panel } from '../../../../../components/common';

interface RequestProgressProps {
  request: RollingFundPRequest;
  refetch: Function;
  isRefetching?: boolean;
  setIsRefetching?: Function;
  documentSignModule?: any;
}

interface LinearProgressStyledProps extends LinearProgressProps {
  barColor?: string;
}

const LinerProgressStyled = styled(LinearProgress, {
  shouldForwardProp: (prop: string) => !['barColor'].includes(prop)
})<LinearProgressStyledProps>(({ theme, barColor }) => ({
  [`&.MuiLinearProgress-root`]: {
    backgroundImage: 'none',
    animation: 'none',
    backgroundColor: '#E1E2E3'
  },
  [`.MuiLinearProgress-bar`]: {
    backgroundColor: barColor
  }
}));

const stepsData: Partial<Record<PRequestStepCodeEnum, any>> = {
  requestCreation: {
    isCompleted: {
      title: 'Request processing',
      description: 'We are processing your request. Please wait for the approval.'
    }
  },
  requestApproval: {
    isRejected: {
      title: 'Request is declined',
      description: 'Please refer to the history to see the reason for rejection. You can change terms and submit again.'
    },
    isCompleted: {
      title: 'Sign Contract',
      description: 'Please send the funds to the project contribution wallet and sign Contract.'
    }
  },
  requestSaftSignatureBuyer: {
    isCompleted: {
      title: 'Contract processing',
      description: 'We are checking your Contract and will countersign it soon.'
    }
  },
  requestSaftSignatureSeller: {
    isCompleted: {
      title: 'Completed',
      description: 'The process is now completed. You can download the countersigned Contract down below.'
    }
  },
};

const stepsDataLegal: Record<PRequestStepCodeLegalEnum, any> = {
  requestCreation: {
    isCompleted: {
      title: 'Request processing',
      description: 'Once your KYB is approved, you’ll receive Contract to your email address.'
    },
    isRejected: {
      title: 'Request is declined',
      description: 'Please refer to the history to see the reason for rejection. You can change terms and submit again.'
    }
  }
};

export const RequestProgress = (props: RequestProgressProps) => {
  const { request, documentSignModule } = props;
  const { documentsViewData } = documentSignModule;

  const progress = request.progress;
  const steps = progress?.steps;

  const step = steps.filter(step => step.isComplete).pop();
  const stepData = request.isLegalPerson ? stepsDataLegal['requestCreation'] : stepsData[step.code];
  const data = step.isError ? stepData.isRejected : stepData.isCompleted;
  const stepsToComplete = steps.filter(step => !step.isComplete);
  const isCompletedRequest = progress?.isComplete;

  return (
    <>
      <Panel isLoading={documentSignModule.isRefetching}>
        <Grid container>
          <Grid item xs={12}>
            <div className="request-progress">
              {!request.isLegalPerson &&
                <div className="request-progress__steps">
                  <Grid container spacing={0.3}>
                    {
                      steps.map((step: PRequestProgressStep, index: number) => {
                        const isCompletedStep = step.isComplete;
                        const isRejectedStep = step.isError;

                        let color = '';
                        if (isCompletedRequest) {
                          color = '#2BB596';
                        } else if (isCompletedStep) {
                          if (isRejectedStep) {
                            color = '#E2507A';
                          } else {
                            color = '#4A4DF0';
                          }
                        }

                        const value = isCompletedRequest || isCompletedStep || isRejectedStep ? 100 : -100;

                        return <Grid item xs={12 / steps.length} key={index}>
                          <LinerProgressStyled variant="determinate" value={value} barColor={color}/>
                        </Grid>;
                      })
                    }
                  </Grid>
                </div>
              }
              <div className="step">
                <div className="step__info">
                  {!request.isLegalPerson &&
                    <div className="step__completion">
                      {
                        stepsToComplete.length > 0 &&
                        <Typography component="span"
                                    sx={{ color: '#7A858F', fontWeight: 400, fontSize: '14px' }}>
                          +{stepsToComplete.length} more steps to complete
                        </Typography>
                      }
                    </div>
                  }
                  <div className="step__data">
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                      <div className="step__title">
                        <Typography component="span" sx={{ fontWeight: 500, fontSize: '20px' }}>
                          {data.title}
                        </Typography>
                      </div>
                      <div className="step__description">
                        <Typography component="span" sx={{ fontWeight: 400, fontSize: '14px', color: '#7A858F' }}>
                          {data.description}
                        </Typography>
                      </div>
                    </div>
                    {documentsViewData.currentUserAction &&
                      <div className="step__button">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={documentsViewData.currentUserAction.actionHandler}
                        >{documentsViewData.currentUserAction.label}</Button>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
};
